<template>
  <div>
    <modal
      name="new-edit-song"
      height="auto"
      width="90%"
      :scrollable="true"
      transition="pop-out"
      :click-to-close="false"
      @before-open="beforeOpen"
      @before-close="beforeClose"
    >
      <form class="show-form" autocomplete="off" @submit.prevent>
        <input autocomplete="false" name="hidden" type="text" style="display:none;" />
        <h4 v-if="currentSong._id">
          Edit Song
        </h4>
        <h4 v-else>
          New Song
        </h4>
        <div class="row">
          <div class="col-12">
            <!-- Show Title -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="songTitle">Song Title</label>
              </div>
              <div class="col-6">
                <input
                  ref="songTitle"
                  type="text"
                  class="form-control"
                  v-model.trim="currentSong.title"
                  id="songTitle"
                  name="songTitle"
                  :class="{ 'is-invalid': attemptSubmit && missingTitle }"
                  placeholder="Enter Song Title"
                />
                <div :class="[attemptSubmit && missingTitle ? 'is-not-valid' : 'is-valid']">
                  Title is required.
                </div>
              </div>
            </div>

            <!-- Song ID -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label not-required" for="songId">Song ID</label>
              </div>
              <div class="col-6">
                <input type="text" class="form-control" v-model="currentSong._id" id="songId" disabled />
              </div>
              <div class="col-2">
                <span @click="testDetailPage" v-if="currentSong._id" class="link">song detail page</span>
              </div>
            </div>

            <!-- Show Title -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label not-required" for="showTitle">Show Title</label>
              </div>
              <div class="col-6">
                <input type="text" class="form-control" v-model.trim="currentSong.showTitle" id="showTitle" disabled />
              </div>
            </div>

            <!-- Date Entered -->
            <div class="row form-row" v-if="currentSong._id">
              <div class="col-4 label">
                <label class="label not-required" for="songDateEntered">Date Entered</label>
              </div>
              <div class="col-6">
                <span>{{ currentSong.dateCreated | formatDate }}</span>
              </div>
            </div>

            <!-- Last Updated -->
            <div class="row form-row" v-if="currentSong._id">
              <div class="col-4 label">
                <label class="label not-required" for="songLastUpdated">Last Updated</label>
              </div>
              <div class="col-6">
                <span>{{ currentSong.lastUpdated | formatDate }}</span>
              </div>
            </div>

            <!-- Last Updated By-->
            <div class="row form-row" v-if="currentSong._id">
              <div class="col-4 label">
                <label class="label not-required" for="songLastUpdatedBy">Last Updated By</label>
              </div>
              <div class="col-6">
                <span>{{ currentSong.lastUpdatedByName | emptyVal }}</span>
              </div>
            </div>

            <!-- Composers -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="composers">Composer(s)</label>
                <div>
                  <span v-if="currentSong.hasFemaleComposer">👩🏻 </span>
                  <span v-if="currentSong.hasBIPOCComposer">👨🏾</span>
                </div>
              </div>
              <div class="col-6">
                <v-select
                  ref="composer"
                  multiple
                  @input="composerEntered"
                  :close-on-select="true"
                  v-model="currentSong.composers"
                  id="composers"
                  name="composers"
                  :options="composers"
                />
                <span class="note">Format: Last name, First name</span>
                <div v-for="composer of currentSong.composers" :key=composer._id>
                    <span v-if="composer.infoUrl">
                      <b><i>{{composer.lastName}}:</i></b> <a :href="composer.infoUrl" target="__blank">{{composer.infoUrl}}</a>
                    </span>
                  </div>
                <div :class="[attemptSubmit && missingComposer ? 'is-not-valid' : 'is-valid']">
                  At least one composer is required.
                </div>
              </div>
            </div>

            <!-- Lyricists -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="lyricists">Lyricist(s)</label>
                <div>
                  <span v-if="currentSong.hasFemaleLyricist">👩🏻 </span>
                  <span v-if="currentSong.hasBIPOCLyricist">👨🏾</span>
                </div>
              </div>
              <div class="col-6">
                <v-select
                  multiple
                  @input="lyricistEntered"
                  :close-on-select="true"
                  v-model="currentSong.lyricists"
                  id="lyricists"
                  name="lyricists"
                  :options="lyricists"
                />
                <span class="note">Format: Last name, First name</span>
                <div v-for="lyricist of currentSong.lyricists" :key=lyricist._id>
                    <span v-if="lyricist.infoUrl">
                      <b><i>{{lyricist.lastName}}:</i></b> <a :href="lyricist.infoUrl" target="__blank">{{lyricist.infoUrl}}</a>
                    </span>
                  </div>
                <div :class="[attemptSubmit && missingLyricist ? 'is-not-valid' : 'is-valid']">
                  At least one lyricist is required.
                </div>
              </div>
            </div>

            <!-- Character's Age -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="age">Character's Age</label>
              </div>
              <div class="col-6">
                <age :current-song="currentSong" name="age" />
                <div :class="[attemptSubmit && missingAge ? 'is-not-valid' : 'is-valid']">
                  At least one age group is required.
                </div>
              </div>
            </div>

            <!-- Tags -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label not-required" for="tags">Tags (keywords)</label>
              </div>
              <div class="col-6">
                <v-select
                  multiple
                  v-model="currentSong.tags"
                  :close-on-select="false"
                  id="tags"
                  name="tags"
                  :options="tagsList"
                />
              </div>
            </div>

            <!-- Year Written -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="year">Year Written</label>
              </div>
              <div class="col-6">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="currentSong.yearWritten"
                  id="year"
                  name="year"
                  :class="{ 'is-invalid': attemptSubmit && missingYear }"
                  placeholder="Enter Year Written"
                />
                <div :class="[attemptSubmit && missingYear ? 'is-not-valid' : 'is-valid']">
                  A valid year is required.
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- The Buttons and Section Header-->
        <div class="row justify-content-between">
          <div class="col-5">
            <h6 style="padding-top:11px;">
              Musical Parameters
            </h6>
          </div>
          <div class="col-5">
            <span class="text-danger" v-if="formErrorText">{{ formErrorText }}<br /></span>
            <span class="text-danger" v-if="error">{{ error }}<br /></span>
            <button @click="cancel" class="btn-margin btn btn-outline-secondary btn-xs">
              Cancel
            </button>
            <button @click="save" :disabled="formsHasErrors" class="btn-margin btn btn-outline-success btn-xs">
              Save
            </button>
            <button
              @click="saveNew"
              v-if="loggedinUser && loggedinUser.role !== 'creator'"
              :disabled="formsHasErrors"
              class="btn-margin btn btn-outline-primary btn-xs"
            >
              Save and New
            </button>
            <button
              @click="delSong"
              v-if="$can('delete', 'songs') && currentSong._id"
              class="btn-margin btn btn-outline-danger btn-xs"
            >
              Delete
            </button>
          </div>
        </div>
        <hr style="margin-top: 0px;" />

        <!-- Musical Parameters -->
        <div class="row">
          <div class="col-12">
            <!-- Style -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="style">Style</label>
              </div>
              <div class="col-6">
                <input type="radio" name="style" :value="true" v-model="currentSong.isComic" />
                <label for="style1">Comedic</label>
                <input type="radio" name="style" :value="false" v-model="currentSong.isComic" />
                <label for="style2">Dramatic</label>
              </div>
            </div>

            <!-- Voice Type -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="voice">Voice Type</label>
              </div>
              <div class="col-3">
                <select v-model="currentSong.voiceType" name="voice-type">
                  <option>Soprano</option>
                  <option>Mezzo/Belt/Alto</option>
                  <option>Tenor</option>
                  <option>Baritone/Bass</option>
                  <option>Trans & Nonbinary</option>
                  <option>Not Applicable</option>
                </select>
              </div>
              <div class="col-5">
                <span :class="[attemptSubmit && missingVoiceType ? 'is-not-valid' : 'is-valid']">Voice Type is required.</span>
              </div>
            </div>

            <!-- Tempo -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="tempo">Tempo</label>
              </div>
              <div class="col-3">
                <select v-model="currentSong.tempo" name="tempo">
                  <option>Easy Ballad</option>
                  <option>Power Ballad</option>
                  <option>Medium/Moderate</option>
                  <option>Up-Tempo</option>
                </select>
              </div>
              <div class="col-5">
                <span :class="[attemptSubmit && missingTempo ? 'is-not-valid' : 'is-valid']">Tempo is required.</span>
              </div>
            </div>

            <!-- Original Key -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="key">Original Key</label>
              </div>
              <div class="col-3">
                <select v-model="currentSong.originalKey.pitch" name="pitch">
                  <option>A</option>
                  <option>A♯</option>
                  <option>A♭</option>
                  <option>B</option>
                  <option>B♭</option>
                  <option>C</option>
                  <option>C♯</option>
                  <option>D</option>
                  <option>D♯</option>
                  <option>D♭</option>
                  <option>E</option>
                  <option>E♭</option>
                  <option>F</option>
                  <option>F♯</option>
                  <option>G</option>
                  <option>G♯</option>
                  <option>G♭</option>
                </select>
                <input type="radio" id="key1" name="pitch" value="major" v-model="currentSong.originalKey.majorMinor" />
                <label for="key1">Major</label>
                <input type="radio" id="key2" name="pitch" value="minor" v-model="currentSong.originalKey.majorMinor" />
                <label for="ke21">Minor</label>
              </div>
              <div class="col-5">
                <span :class="[attemptSubmit && missingKey ? 'is-not-valid' : 'is-valid']">Key is required.</span>
              </div>
            </div>

            <!-- Difficulty -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="difficulty">Difficulty for accompanist</label>
              </div>
              <div class="col-3">
                <select v-model="currentSong.accompanistDifficulty" name="difficulty">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
              <div class="col-5">
                <span :class="[attemptSubmit && missingDifficulty ? 'is-not-valid' : 'is-valid']">Difficulty is required.</span>
              </div>
            </div>

            <!-- Time Signature -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="timesignature">Time Signature</label>
              </div>
              <div class="col-3">
                <select v-model.trim="currentSong.timeSignature1" name="timesignature">
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>9</option>
                  <option>12</option>
                </select>
                <select v-model.trim="currentSong.timeSignature2" name="timesignature">
                  <option>2</option>
                  <option>4</option>
                  <option>8</option>
                </select>
              </div>
              <div class="col-5">
                <span :class="[attemptSubmit && missingTimeSignature ? 'is-not-valid' : 'is-valid']"
                  >Time Signature is required.</span
                >
              </div>
            </div>

            <!-- Voice Range -->
            <div class="row form-row" style="margin-bottom:0px;">
              <div class="col-4 label">
                <label class="label required" for="voicerange">Voice Range</label>
              </div>
              <div class="col-3">
                <select v-model="currentSong.voiceRange.lowestNote.note" name="voicerange">
                  <option>A</option>
                  <option>A♯</option>
                  <option>A♭</option>
                  <option>B</option>
                  <option>B♭</option>
                  <option>C</option>
                  <option>C♯</option>
                  <option>D</option>
                  <option>D♯</option>
                  <option>D♭</option>
                  <option>E</option>
                  <option>E♭</option>
                  <option>F</option>
                  <option>F♯</option>
                  <option>G</option>
                  <option>G♯</option>
                  <option>G♭</option>
                </select>
                <select v-model="currentSong.voiceRange.lowestNote.octave" name="voicerange">
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                </select>
                to
                <select v-model="currentSong.voiceRange.highestNote.note" name="voicerange">
                  <option>A</option>
                  <option>A♯</option>
                  <option>A♭</option>
                  <option>B</option>
                  <option>B♭</option>
                  <option>C</option>
                  <option>C♯</option>
                  <option>D</option>
                  <option>D♯</option>
                  <option>D♭</option>
                  <option>E</option>
                  <option>E♭</option>
                  <option>F</option>
                  <option>F♯</option>
                  <option>G</option>
                  <option>G♯</option>
                  <option>G♭</option>
                </select>
                <select v-model="currentSong.voiceRange.highestNote.octave" name="voicerange">
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                </select>
              </div>
              <div class="col-5">
                <span :class="[attemptSubmit && missingVoiceRange ? 'is-not-valid' : 'is-valid']">Voice Range is required.</span>
              </div>
            </div>
            <div class="row form-row" style="margin-bottom:10px;">
              <div class="col-4 label" />
              <div class="col-6">
                <i>Middle C = C4</i>
              </div>
            </div>

            <!-- Page Numbers -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="pages">Page Numbers in the Score</label>
              </div>
              <div class="col-3">
                from
                <input
                  type="text"
                  class="form-control"
                  style="width:70px;display:inline;"
                  v-model.trim="currentSong.pageNumbers.start"
                  id="pages-start"
                  name="pages-start"
                  placeholder="Start Page"
                />
                to
                <input
                  type="text"
                  class="form-control"
                  style="width:70px;display:inline;"
                  v-model.trim="currentSong.pageNumbers.end"
                  id="pages-end"
                  name="pages-end"
                  placeholder="End Page"
                />
              </div>
              <div class="col-5">
                <span :class="[attemptSubmit && missingPages ? 'is-not-valid' : 'is-valid']">Page Numbers are required.</span>
              </div>
            </div>

            <!-- Comments -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label not-required" for="comments">Comments</label>
              </div>
              <div class="col-6">
                <select
                  class="comments-select"
                  name="comments"
                  id="comments"
                  v-model.trim="selectComment"
                  :required="true"
                  @change="addComment"
                >
                  <option :selected="true">
                    (Optional) Select Template
                  </option>
                  <option v-for="comment in comments" :key="comment._id" :value="comment.text">
                    {{ comment.text }}
                  </option>
                </select>
                <textarea class="comments-text" v-model.trim="currentSong.comments" />
              </div>
            </div>

            <!-- Founders Pick -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label not-required" for="foundersPick">Founders pick</label>
              </div>
              <div class="col-6">
                <input type="checkbox" id="founders" class="css-checkbox" v-model="currentSong.isRecommended" />
                <label for="founders" name="founders_lbl" class="css-label lite-red-check">Yes</label>
              </div>
            </div>

            <!-- Popularity -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label not-required" for="popularity">Popularity</label>
              </div>
              <div class="col-6">
                <select v-model="currentSong.popularity">
                  <option value="1">
                    Very popular
                  </option>
                  <option value="2">
                    Somewhat popular
                  </option>
                  <option value="3">
                    Heard occassionally
                  </option>
                  <option value="4">
                    Not heard a lot
                  </option>
                  <option value="5">
                    Rare find
                  </option>
                </select>
              </div>
            </div>

            <!-- Rare Find -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label mult-lines" for="rarefind"
                  >[OLD - For new songs or updates, use "Popularity" field] Rare Find</label
                >
              </div>
              <div class="col-6">
                <input type="checkbox" id="rarefind" class="css-checkbox" v-model="currentSong.isRareFind" />
                <label for="rarefind" name="rarefind_lbl" class="css-label lite-red-check">Yes</label>
              </div>
            </div>
          </div>
        </div>

        <!-- The Buttons and Section Header-->
        <div class="row justify-content-between">
          <div class="col-5">
            <h6 style="padding-top:11px;">
              External Links
            </h6>
          </div>
          <div class="col-5">
            <span class="text-danger" v-if="formErrorText">{{ formErrorText }}<br /></span>
            <span class="text-danger" v-if="error">{{ error }}<br /></span>
            <button @click="cancel" class="btn-margin btn btn-outline-secondary btn-xs">
              Cancel
            </button>
            <button @click="save" :disabled="formsHasErrors" class="btn-margin btn btn-outline-success btn-xs">
              Save
            </button>
            <button
              @click="saveNew"
              v-if="loggedinUser && loggedinUser.role !== 'creator'"
              :disabled="formsHasErrors"
              class="btn-margin btn btn-outline-primary btn-xs"
            >
              Save and New
            </button>
            <button
              @click="delSong"
              v-if="$can('delete', 'songs') && currentSong._id"
              class="btn-margin btn btn-outline-danger btn-xs"
            >
              Delete
            </button>
          </div>
        </div>
        <hr style="margin-top: 0px;" />

        <!-- External Links -->
        <div>
          <!-- Wikipedia -->
          <div class="row external-links">
            <h6>Info / Wikipedia Link</h6>
          </div>
          <div class="row">
            <div class="col-6">
              <p class="small-text">
                Use this field only if the link for the song is different from the link for the show.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <input class="row external-links wiki-link" type="text" placeholder="http://" v-model="currentSong.wikipediaUrl" />
            </div>
            <div class="col-4 wiki-link">
              <span @click="testWikiLink" class="link" style="margin-left:-75px;">Test Link</span>
            </div>
          </div>

          <!-- Scores -->
          <div class="row external-links">
            <h6>Scores</h6>
          </div>

          <!-- Scores MusicNotes -->
          <div class="row external-links-link">
            <div class="col-10">
              <external-links1
                :model="currentSong"
                :type="'scores'"
                :name="'musicNotes'"
                :base-url="'https://www.musicnotes.com/landing?w='"
              />
            </div>
          </div>

          <!-- Scores  SheetMusicPlus-->
          <div class="row external-links-link">
            <div class="col-10">
              <external-links1
                :model="currentSong"
                :type="'scores'"
                :name="'sheetMusicPlus'"
                :base-url="'https://www.sheetmusicplus.com/search?Ntt='"
              />
            </div>
          </div>

          <!-- Scores NewMusicalTheatre -->
          <div class="row external-links-link">
            <div class="col-10">
              <external-links1
                :model="currentSong"
                :type="'scores'"
                :name="'newMusicalTheatre'"
                :base-url="'http://newmusicaltheatre.com/catalogsearch/result/?q='"
              />
            </div>
          </div>

          <!-- Scores Amazon -->
          <div class="row external-links-link">
            <div class="col-10">
              <external-links1
                :model="currentSong"
                :type="'scores'"
                :name="'amazon'"
                :base-url="'https://www.amazon.com/s/ref=nb_sb_noss?url=search-alias%3Daps&field-keywords=+sheet+music+'"
              />
            </div>
          </div>

          <!-- Scores Worldcat -->
          <div class="row external-links-link">
            <div class="col-10">
              <external-links1
                :model="currentSong"
                :type="'scores'"
                :name="'worldcat'"
                :base-url="'http://www.worldcat.org/search?qt=affiliate&ai=Musical_musicaltheatersongs&fq=x0%3Amsscr&q=ti%3A'"
              />
            </div>
          </div>

          <!-- Scores ContemporaryMusicalTheatre -->
          <div class="row external-links-link">
            <div class="col-10">
              <external-links1
                :model="currentSong"
                :type="'scores'"
                :name="'contemporaryMusicalTheatre'"
                :base-url="'https://www.contemporarymusicaltheatre.com/'"
              />
            </div>
          </div>

          <!-- Scores Custom -->
          <div class="row external-links-link">
            <div class="col-10">
              <custom-link1 :model="currentSong" :type="'scores'" />
            </div>
          </div>

          <!-- Recordings -->
          <div class="row external-links">
            <h6>Recordings</h6>
          </div>

          <!-- Recordings Amazon -->
          <div class="row external-links-link">
            <div class="col-10">
              <external-links1
                :model="currentSong"
                :type="'recordings'"
                :name="'amazon'"
                :base-url="'https://www.amazon.com/s/ref=nb_sb_noss?url=search-alias%3Daps&field-keywords=music+'"
              />
            </div>
          </div>

          <!-- Recordings iTunes -->
          <div class="row external-links-link">
            <div class="col-10">
              <external-links1
                :model="currentSong"
                :type="'recordings'"
                :name="'iTunes'"
                :base-url="'https://fnd.io/#/us/search?mediaType=music&term='"
              />
            </div>
          </div>

          <!-- Recordings Spotify -->
          <div class="row external-links-link">
            <div class="col-10">
              <external-links1
                :model="currentSong"
                :type="'recordings'"
                :name="'spotify'"
                :base-url="'https://open.spotify.com/search/'"
              />
            </div>
          </div>

          <!-- Recordings SoundCloud -->
          <div class="row external-links-link">
            <div class="col-10">
              <external-links1
                :model="currentSong"
                :type="'recordings'"
                :name="'soundCloud'"
                :base-url="'https://soundcloud.com/search?q='"
              />
            </div>
          </div>

          <!-- Recordings WorldCat -->
          <div class="row external-links-link">
            <div class="col-10">
              <external-links1
                :model="currentSong"
                :type="'recordings'"
                :name="'worldcat'"
                :base-url="'http://www.worldcat.org/search?qt=affiliate&ai=Musical_musicaltheatersongs&fq=x0%3Amusic&q='"
              />
            </div>
          </div>

          <!-- Recordings ContemporaryMusicalTheatre -->
          <div class="row external-links-link">
            <div class="col-10">
              <external-links1
                :model="currentSong"
                :type="'recordings'"
                :name="'contemporaryMusicalTheatre'"
                :base-url="'https://www.contemporarymusicaltheatre.com/'"
              />
            </div>
          </div>

          <!-- Recordings NewMusicalTheatre -->
          <div class="row external-links-link">
            <div class="col-10">
              <external-links1
                :model="currentSong"
                :type="'recordings'"
                :name="'newMusicalTheatre'"
                :base-url="'http://newmusicaltheatre.com/catalogsearch/result/?q='"
              />
            </div>
          </div>

          <!-- Recordings Gilbert and Sullivan Archive -->
          <div class="row external-links-link">
            <div class="col-10">
              <external-links1
                :model="currentSong"
                :type="'recordings'"
                :name="'gsarchive'"
                :base-url="'http://search.freefind.com/find.html?id=2743017&pid=r&mode=ALL&n=0&submit=+Find%21+&query='"
              />
            </div>
          </div>

          <!-- Recordings Custom -->
          <div class="row external-links-link">
            <div class="col-10">
              <custom-link1 :model="currentSong" :type="'recordings'" />
            </div>
          </div>
        </div>

        <!-- The Buttons and Section Header-->
        <div class="row justify-content-between">
          <div class="col-5" />
          <div class="col-5">
            <p />
            <span class="text-danger" v-if="formErrorText">{{ formErrorText }}<br /></span>
            <span class="text-danger" v-if="error">{{ error }}<br /></span>
            <button @click="cancel" class="btn-margin btn btn-outline-secondary btn-xs">
              Cancel
            </button>
            <button @click="save" :disabled="formsHasErrors" class="btn-margin btn btn-outline-success btn-xs">
              Save
            </button>
            <button
              @click="saveNew"
              v-if="loggedinUser && loggedinUser.role !== 'creator'"
              :disabled="formsHasErrors"
              class="btn-margin btn btn-outline-primary btn-xs"
            >
              Save and New
            </button>
            <button
              @click="delSong"
              v-if="$can('delete', 'songs') && currentSong._id"
              class="btn-margin btn btn-outline-danger btn-xs"
            >
              Delete
            </button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Age from '../components/Age.vue'
  import ExternalLinks1 from '../components/ExternalLinks.1.vue'
  import CustomLink1 from '../components/CustomLink.1.vue'
  import { compLyrc } from '../mixins/newCompLyrc.js'
  import { eventbus } from '../main'
  export default {
    components: {
      Age,
      ExternalLinks1,
      CustomLink1
    },
    mixins: [compLyrc],
    data() {
      return {
        attemptSubmit: false,
        selectComment: '(Optional) Select Template',
        error: '',
        showTitle: '',
        showId: '',
        saveAndNewClicked: false
      }
    },
    // created() {
    //   eventbus.$on('SongCreated', () => {
    //     // When SaveNew was used the _id property should be reset
    //     // when a Song was created
    //     if (this.saveAndNewClicked) {
    //       this.currentSong._id = ''
    //       this.$log.info('Song Id reset because saveAndNewClicked')
    //     }
    //   })
    // },
    // beforeDestroy() {
    //   eventbus.$off('SongCreated')
    // },
    computed: {
      ...mapState({
        currentShow: state => state.shows.currentShow,
        currentSong: state => state.songs.currentSong,
        composers: state => state.creators.composersList,
        lyricists: state => state.creators.lyricistsList,
        // songsList: state => state.songs.songsList,
        // showsList: state => state.shows.showsList,
        tagsList: state => state.glossary.tagsList,
        loggedinUser: state => state.general.loggedinUser,
        siteLink: state => state.general.siteLink,
        comments: state => state.general.commentsList,
        externalLinks: state => state.songs.externalLinks
      }),
      formErrorText() {
        return this.formsHasErrors ? 'Please correct one or more errors' : ''
      },
      formsHasErrors() {
        if (this.attemptSubmit) {
          return (
            this.missingTitle ||
            this.missingComposer ||
            this.missingLyricist ||
            this.missingYear ||
            this.missingDifficulty ||
            this.missingTimeSignature ||
            this.missingVoiceRange ||
            this.missingPages ||
            this.missingAge ||
            this.missingVoiceType ||
            this.missingTempo ||
            this.missingKey
          )
        } else {
          return false
        }
      },
      missingTitle() {
        return this.currentSong.title === ''
      },
      missingComposer() {
        return this.currentSong.composers.length === 0 && !this.currentSong.isCompilation
      },
      missingLyricist() {
        return this.currentSong.lyricists.length === 0 && !this.currentSong.isCompilation
      },
      missingYear() {
        return this.currentSong.yearWritten.length === 0
      },
      missingAge() {
        return this.currentSong.ageRanges.length === 0
      },
      missingVoiceType() {
        return this.currentSong.voiceType.length === 0
      },
      missingTempo() {
        return this.currentSong.tempo.length === 0
      },
      missingKey() {
        return this.currentSong.originalKey.pitch.length === 0 || this.currentSong.originalKey.majorMinor.length === 0
      },
      missingDifficulty() {
        return this.currentSong.accompanistDifficulty === 0
      },
      missingTimeSignature() {
        if (this.currentSong.timeSignature1 && this.currentSong.timeSignature2) {
          return this.currentSong.timeSignature1.length === 0 || this.currentSong.timeSignature2.length === 0
        } else {
          return this.currentSong.timeSignature.length === 0
        }
      },
      missingVoiceRange() {
        return (
          this.currentSong.voiceRange.lowestNote.note.length === 0 ||
          this.currentSong.voiceRange.highestNote.note.length === 0 ||
          this.currentSong.voiceRange.lowestNote.octave.length === 0 ||
          this.currentSong.voiceRange.highestNote.octave.length === 0
        )
      },
      missingPages() {
        return this.currentSong.pageNumbers.start.length === 0 || this.currentSong.pageNumbers.end.length === 0
      }
    },
    props: ['onClose'],
    methods: {
      beforeOpen() {
        this.attemptSubmit = false
        this.saveAndNewClicked = false
        document.addEventListener('keyup', this.close)
      },
      beforeClose() {
        document.removeEventListener('keyup', this.close)
      },
      close(e) {
        if (e.keyCode === 27) {
          eventbus.$emit('modal', { command: 'close', name: 'new-edit-song' })
        }
      },
      testWikiLink() {
        window.open(this.currentSong.wikipediaUrl, '_blank')
      },
      testDetailPage() {
        window.open(this.siteLink + '/song/' + this.currentSong._id, '_blank')
      },
      cancel() {
        eventbus.$emit('modal', { command: 'close', name: 'new-edit-song' })
      },
      addComment() {
        this.currentSong.comments = this.selectComment + '\n' + this.currentSong.comments
        this.selectComment = '(Optional) Select Template'
      },
      save() {
        this.attemptSubmit = true
        if (!this.formsHasErrors) {
          this.saveSong()
          this.saveAndNewClicked = false
          this.$modal.hide('new-edit-song')
          eventbus.$emit('modal', { command: 'close', name: 'new-edit-song' })
        }
      },
      saveNew() {
        this.attemptSubmit = true
        if (!this.formsHasErrors) {
          // Store ShowId and ShowTitle so it can be assigned to the new song
          this.showTitle = this.currentSong.showTitle
          this.showId = this.currentSong.showId
          this.$log.info(`Will create a new song with show title ${this.showTitle} and a show id of ${this.showId}`)
          this.saveAndNewClicked = true
          this.saveSong()
          this.attemptSubmit = false
          // Set currentSong to new song with empty values and set some other values
          this.$store.dispatch('songs/setNewSong', { showId: this.showId, showTitle: this.showTitle, _id: null })
          this.$refs.songTitle.focus()
        }
      },
      delSong() {
        if (confirm(`Are you sure you want to delete the song "${this.currentSong.title}"?\n\nThis action cannot be undone.`)) {
          this.$modal.hide('new-edit-song')
          this.$log.info(`deleting song ${this.currentSong.title} ${this.currentSong._id}`)
          this.$store.dispatch('songs/deleteSong', this.currentSong._id)
        }
      },
      composerEntered(val) {
        if (val && val.length > 0) {
          const composer = val[0].label
          if (composer) {
            this.currentSong.sortComposer = composer.replace(/[^\w\s]/g, '')
          }
          this.currentSong.hasBIPOCComposer = false
          this.currentSong.hasFemaleComposer = false
          for (const comp of this.currentSong.composers) {
            if (comp.isFemale) {
              this.currentSong.hasFemaleComposer = true
            }
            if (comp.isBIPOC) {
              this.currentSong.hasBIPOCComposer = true
            }
          }
        }
      },
      lyricistEntered(val) {
        if (val && val.length > 0) {
          const lyricist = val[0].label
          if (lyricist) {
            this.currentSong.sortLyricist = lyricist.replace(/[^\w\s]/g, '')
          }
          this.currentSong.hasBIPOCLyricist = false
          this.currentSong.hasFemaleLyricist = false
          for (const lyrc of this.currentSong.lyricists) {
            if (lyrc.isFemale) {
              this.currentSong.hasFemaleLyricist = true
            }
            if (lyrc.isBIPOC) {
              this.currentSong.hasBIPOCLyricist = true
            }
          }
        }
      },
      saveSong() {
        // Fix tags
        this.currentSong.tags = this.fixTags(this.currentSong.tags)
        this.$store.dispatch('songs/saveSong')
      },
      fixTags(tags) {
        this.$log.info('Tag changed', tags)
        // TagsList holds objects, we just want a sting array
        const newTags = []
        for (var tag of tags) {
          if (typeof tag === 'object') {
            newTags.push(tag.term)
          } else {
            newTags.push(tag)
          }
        }
        return newTags
      }
    }
  }
</script>
