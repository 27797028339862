<template>
  <div>
    <modal
      id="user-edit"
      @before-open="beforeOpen"
      @before-close="beforeClose"
      name="user-edit"
      height="auto"
      width="800"
      :scrollable="true"
      transition="pop-out"
      :click-to-close="false"
    >
      <div class="show-form">
        <h4 v-if="currentUser._id">
          Edit User
        </h4>
        <h4 v-else>
          New User
        </h4>
        <div class="row">
          <div class="col-12 form-block">
            <!-- First Name -->
            <div class="row form-row">
              <div class="col-2 label">
                <label class="label required" for="firstName">First Name</label>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <input
                    id="firstName"
                    name="firstName"
                    class="form-control"
                    type="text"
                    v-model.trim="currentUser.firstName"
                    :class="{ 'is-invalid': attemptSubmit && missingFirstName }"
                  />
                  <div :class="[attemptSubmit && missingFirstName ? 'is-not-valid' : 'is-valid']">
                    First Name is required.
                  </div>
                </div>
              </div>
            </div>
            <!-- Last Name -->
            <div class="row form-row">
              <div class="col-2 label">
                <label class="label required" for="lastName">Last Name</label>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <input
                    id="lastName"
                    name="lastName"
                    class="form-control"
                    type="text"
                    v-model.trim="currentUser.lastName"
                    :class="{ 'is-invalid': attemptSubmit && missingLastName }"
                  />
                  <div :class="[attemptSubmit && missingLastName ? 'is-not-valid' : 'is-valid']">
                    Last Name is required.
                  </div>
                </div>
              </div>
            </div>

            <!-- Email -->
            <div class="row form-row">
              <div class="col-2 label">
                <label class="label required" for="email">Email</label>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <input
                    id="email"
                    name="email"
                    class="form-control"
                    type="text"
                    v-model.trim="currentUser.email"
                    :class="{ 'is-invalid': attemptSubmit && missingEmail }"
                  />
                  <div v-if="missingEmail" :class="[attemptSubmit && missingEmail ? 'is-not-valid' : 'is-valid']">
                    Email is required.
                  </div>
                  <div v-if="existingEmail" :class="[existingEmail ? 'is-not-valid' : 'is-valid']">
                    Email already exists.
                  </div>
                </div>
              </div>
            </div>

            <!-- Change password, only for existing users -->
            <div v-if="currentUser._id" style="margin-bottom:15px;">
              <div class="row form-row">
                <div class="col-2 label">
                  <label class="label" for="roles">Change password</label>
                </div>

                <div class="col-2">
                  <input type="checkbox" name="chkpw" class="css-checkbox" id="chkpw" v-model="changePassword" />
                  <label for="chkpw" class="css-label lite-red-check" />
                </div>
              </div>
            </div>

            <!-- Password -->
            <div class="row form-row" v-if="changePassword || !currentUser._id">
              <div class="col-2 label">
                <label class="label required" for="password">Password</label>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <input
                    id="password"
                    name="password"
                    class="form-control"
                    type="text"
                    v-model.trim="currentUser.password"
                    :class="{ 'is-invalid': attemptSubmit && missingPassword }"
                  />
                  <div :class="[attemptSubmit && missingPassword ? 'is-not-valid' : 'is-valid']">
                    Password is required.
                  </div>
                </div>
              </div>
            </div>

            <!-- Roles -->
            <div class="row form-row">
              <div class="col-2 label">
                <label class="label required" for="roles">Roles</label>
              </div>

              <div class="col-2">
                <input type="checkbox" name="age" class="css-checkbox" id="age1" value="subscriber" v-model="currentUser.roles" />
                <label for="age1" class="css-label lite-red-check">Subscriber</label>
              </div>
            </div>

            <div class="row form-row">
              <div class="offset-2 col-4">
                <input type="checkbox" name="age" class="css-checkbox" id="age2" value="creator" v-model="currentUser.roles" />
                <label for="age2" class="css-label lite-red-check">Creator</label>
              </div>
            </div>
            <div class="row form-row">
              <div class="offset-2 col-4">
                <input
                  type="checkbox"
                  name="age"
                  class="css-checkbox"
                  id="age3"
                  value="dataAssistant"
                  v-model="currentUser.roles"
                />
                <label for="age3" class="css-label lite-red-check">Data Assistant</label>
              </div>
            </div>
            <div class="row form-row">
              <div class="offset-2 col-4">
                <input
                  type="checkbox"
                  name="age"
                  class="css-checkbox"
                  id="age4"
                  value="marketingAssistant"
                  v-model="currentUser.roles"
                />
                <label for="age4" class="css-label lite-red-check">Marketing Assistant</label>
              </div>
            </div>
            <div class="row form-row">
              <div class="offset-2 col-4">
                <input type="checkbox" name="age" class="css-checkbox" id="age5" value="admin" v-model="currentUser.roles" />
                <label for="age5" class="css-label lite-red-check">Admin</label>
              </div>
            </div>
            <div class="row form-row">
              <div class="offset-2 col-3">
                <input type="checkbox" name="age" class="css-checkbox" id="age6" value="groupadmin" disabled v-model="currentUser.roles" />
                <label for="age6" class="css-label lite-red-check">Group Admin (change in subscription)</label>
              </div>
              <div class="col-6">
                <label for="maxGroupMembers" class="css-label" style="line-height:15px;vertical-align:middle;margin-right:5px;">Max Groups Members</label>
                <input type="text" style="width:40px;margin-right: 5px;;" name="maxGroupMembers" v-model="currentUser.maxGroupMembers">
              </div>
              <!-- <div v-if="currentUser.roles.indexOf('groupadmin') > -1">
                <label for="maxGroupMembers" class="label required extra-role">Max Members</label>
                <input type="text" id="maxGroupMembers" v-model="currentUser.maxGroupMembers">
              </div> -->
            </div>
            <div class="row form-row">
              <div class="offset-4 col-4">
                <div :class="[attemptSubmit && missingRole ? 'is-not-valid' : 'is-valid']">
                  At least one role is required.
                </div>
              </div>
            </div>
            <!-- Date Entered -->
            <div class="row form-row" v-if="currentUser.timestamps && currentUser.timestamps.registered">
              <div class="col-2 label">
                <label class="label not-required" for="songDateEntered">Date Registred</label>
              </div>
              <div class="col-6">
                <span>{{ currentUser.timestamps.registered | formatDate }}</span>
              </div>
            </div>

            <!-- Last Logged in -->
            <div class="row form-row" v-if="currentUser.timestamps && currentUser.timestamps.loggedin">
              <div class="col-2 label">
                <label class="label not-required" for="songLastUpdated">Last Logged-in</label>
              </div>
              <div class="col-6">
                <span>{{ currentUser.timestamps.loggedin | formatDate }}</span>
              </div>
            </div>

            <!-- Last Updated By-->
            <div class="row form-row" v-if="currentUser.lastUpdatedByName">
              <div class="col-4 label">
                <label class="label not-required" for="songLastUpdatedBy">Last Updated By</label>
              </div>
              <div class="col-6">
                <span>{{ currentUser.lastUpdatedByName | emptyVal }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Buttons -->
        <div class="row form-row">
          <div class="offset-8 col-4">
            <span style="color:red;" v-if="currentUser.hasSubscription"
              >User cannot be deleted;<br />associated subscription<br
            /></span>
            <button @click="cancel" class="btn btn-outline-secondary btn-sm">
              Cancel
            </button>
            <button @click="save" :disabled="formsHasErrors" class="btn btn-outline-success btn-sm">
              Save
            </button>
            <button :disabled="!canBeDeleted" @click="delUser" class="btn btn-outline-danger btn-sm">
              Delete
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { eventbus } from '../main'
  export default {
    data() {
      return {
        apiError: '',
        attemptSubmit: false,
        changePassword: false,
        emailExists: false
      }
    },
    props: ['onClose'],
    computed: {
      ...mapState({
        loggedinUser: state => state.general.loggedinUser,
        currentUser: state => state.users.currentUser
      }),
      canBeDeleted() {
        return this.$can('delete', 'users') && this.currentUser._id && !this.currentUser.hasSubscription
      },
      formErrorText() {
        return this.formsHasErrors ? 'Please correct one or more errors' : ''
      },
      formsHasErrors() {
        if (this.attemptSubmit) {
          return (
            this.missingFirstName ||
            this.missingLastName ||
            this.missingEmail ||
            this.missingPassword ||
            this.emailExists
          )
        } else {
          return false
        }
      },
      missingFirstName() {
        return this.currentUser.firstName === ''
      },
      missingLastName() {
        return this.currentUser.lastName === ''
      },
      missingEmail() {
        return this.currentUser.email === ''
      },
      existingEmail() {
        return this.emailExists
      },
      missingPassword() {
        return this.currentUser.password === ''
      },
      missingRole() {
        if (this.currentUser && this.currentUser.roles) {
          return this.currentUser.roles.length === 0
        } else {
          return false
        }
      }
    },
    methods: {
      beforeOpen() {
        // Set init values
        this.attemptSubmit = false
        this.changePassword = false
        console.log('[User Edit] beforeOpen called')
        document.addEventListener('keyup', this.close)
      },
      beforeClose() {
        console.log('[User Edit] beforeClose called')
        document.removeEventListener('keyup', this.close)
      },
      close(e) {
        if (e.keyCode === 27) {
          console.log('[User Edit] escape pressed')
          eventbus.$emit('modal', { command: 'close', name: 'user-edit' })
        }
      },
      cancel() {
        console.log('[User Edit] Cancel called')
        eventbus.$emit('modal', { command: 'close', name: 'user-edit' })
      },
      save() {
        this.attemptSubmit = true
        if (!this.formsHasErrors) {
          console.log('About to save user')
          this.$store.dispatch('users/saveUser', this.currentUser)
          // Closing dialogs moved to store, after success message received
        }
      },
      delUser() {
        console.log('Deleting user with id', this.currentUser._id)
        if (
          confirm(
            `Are you sure you want to delete user "${this.currentUser.firstName} ${this.currentUser.lastName}"?\n\nThis action cannot be undone.`
          )
        ) {
          this.$store.dispatch('users/deleteUser', this.currentUser._id)
          // Closing dialogs moved to store, after success message received)
        }
      }
    }
  }
</script>

<style scoped>
  .extra-role {
    margin-right: 10px;
    padding-left: 20px;
    height: 15px;
    display: inline-block;
    line-height: 15px;
    background-repeat: no-repeat;
    background-position: 0 0;
    font-size: 12px;
    vertical-align: middle;
  }
</style>
