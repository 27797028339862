var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"below-menu"},[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('img',{attrs:{"src":"/loading.gif"}}),_c('div',{staticClass:"loading"},[_vm._v(" "+_vm._s(_vm.loadingMsg)+" ")])]):_vm._e(),(_vm.error)?_c('div',{staticClass:"server-error"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('div',{staticClass:"row fixed-top page-header"},[_vm._m(0),_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"btn btn-outline-primary btn-xs float-right header-btn",staticStyle:{"margin-left":"10px"},on:{"click":_vm.newShow}},[_vm._v(" Add Show ")]),_c('button',{staticClass:"btn btn-outline-primary btn-xs float-right header-btn",on:{"click":_vm.copyContent}},[_vm._v(" Copy ")])])]),(_vm.data)?_c('div',{attrs:{"id":"printable"}},[_c('vue-good-table',{key:_vm.tableKey,attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
        enabled: true,
        perPage: _vm.serverParams.perPage,
        perPageDropdown: [15, 20, 25, 30, 35, 40, 50],
        position: 'top'
      },"line-numbers":false,"sort-options":{
        enabled: true,
        initialSortBy: { field: _vm.initialSortField, type: _vm.initialSortType }
      },"style-class":"vgt-table musical bordered condensed"},on:{"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.rowClick}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-10"},[_c('h5',[_vm._v("Shows")])])}]

export { render, staticRenderFns }