<template>
  <div class="below-menu">
    <!-- Display some nice loading animation -->
    <div class="loading" v-if="loading">
      <img src="/loading.gif" />
      <div class="loading">
        {{ loadingMsg }}
      </div>
    </div>

    <!-- If there are any errors fetching data, display the error -->
    <div v-if="error" class="server-error">
      {{ error }}
    </div>

    <div class="row fixed-top page-header">
      <div class="col-10">
        <h5>Group Users</h5>
      </div>
      <div class="col-2">
        <button @click="newGroupUser" style="margin-left: 10px;" class="btn btn-success btn-xs float-right header-btn">
          Add Group User
        </button>
      </div>
    </div>

    <div class="" style="width: 100%;display:flex;justify-content:space-between">
      <div class="label group-members">Institution: {{ loggedinUser.account.institutionName }}</div>
      <div class="label group-members ">Start: {{ loggedinUser.account.startDate | formatDate }}</div>
      <div class="label group-members ">Expiration: {{ loggedinUser.account.expirationDate | formatDate }}</div>
      <div class="label group-members ">Maximum users allowed: {{ loggedinUser.maxGroupMembers }}</div>
    </div>
    <hr />
    <div class="row">
      <div class="col-10 form-block">
        <!-- First Name -->
        <div class="row form-row">
          <div class="col-2 label">
            <label class="label required" for="firstName">First Name</label>
          </div>
          <div class="col-3">
            <div class="">
              <input ref="firstName" id="firstName" name="firstName" class="form-control" type="text"
                v-model.trim="groupUser.firstName" :disabled="disableInput" />
            </div>
          </div>
          <!-- Last Name -->
          <div class="col-2 label">
            <label class="label required" for="firstName">Last Name</label>
          </div>
          <div class="col-3">
            <div class="">
              <input id="firstName" name="firstName" class="form-control" type="text" v-model.trim="groupUser.lastName"
                :disabled="disableInput" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="offset-2 col-4">
            <div :class="[attemptSubmit && missingFirstName ? 'is-not-valid' : 'is-valid']">
              First Name is required.
            </div>
          </div>
          <div class="offset-1 col-4">
            <div :class="[attemptSubmit && missingLastName ? 'is-not-valid' : 'is-valid']">
              Last Name is required.
            </div>
          </div>
        </div>
        <!-- Email -->
        <div class="row form-row">
          <div class="col-2 label">
            <label class="label required" for="firstName">Email</label>
          </div>
          <div class="col-3">
            <div class="">
              <input id="firstName" name="firstName" class="form-control" type="text" v-model.trim="groupUser.email"
                :disabled="disableInput" />
            </div>
          </div>
          <!-- Password -->
          <div class="col-2 label">
            <label class="label" for="firstName">Password</label>
          </div>
          <div class="col-3">
            <div class="">
              <input id="firstName" name="firstName" class="form-control" type="text" v-model="groupUser.password"
                :disabled="disableInput" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="offset-2 col-4">
            <div :class="[attemptSubmit && missingEmail ? 'is-not-valid' : 'is-valid']">
              Email is required.
            </div>
            <div v-if="existingEmail" :class="[existingEmail ? 'is-not-valid' : 'is-valid']">
              Account already exists.
            </div>
          </div>
          <div class="offset-1 col-4">
            <div :class="[attemptSubmit && missingPassword ? 'is-not-valid' : 'is-valid']">
              Password is required.
            </div>
          </div>
        </div>
      </div>
      <!-- Buttons -->
      <div class="col-2">
        <div class="row from-row">
          <div class="col-12">
            <button @click="cancel" class="btn btn-outline-secondary btn-sm" style="margin-right: 10px;"
              :disabled="disableInput">
              Cancel
            </button>
            <button @click="save" :disabled="formsHasErrors || disableInput" class="btn btn-outline-success btn-sm">
              Save
            </button>
          </div>
        </div>
        <div class="row form-row">
          <div class="col-12">
            <button @click="deleteUser" class="btn btn-danger btn-sm"
              style="margin-right: 10px;margin-top:10px;width:121px" :disabled="canDelete">
              Delete User
            </button>
          </div>
        </div>
      </div>
      <div class="col-10 form-block">
        <div>
        </div>
        <p>
          To add a <strong>new</strong> group user, click on the Add Group User button in the top right of the screen or
          click
          <a href="" @click.prevent="newGroupUser">here</a>. <br />
          To <strong>edit</strong> an existing user, click on the row below with the user you want to edit. Don't forget
          to click
          <strong>Save</strong> to keep you changes.
        </p>
      </div>
    </div>
    <vue-good-table :key="tableKey" :columns="columns" :rows="rows" @on-row-click="rowClick" :pagination-options="{
      enabled: true,
      perPage: 15,
      perPageDropdown: [15, 20, 25, 30, 35, 40, 50],
      position: 'top'
    }" style-class="vgt-table musical bordered condensed" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
const generator = require('generate-password')
export default {
  name: 'Groupusers',
  data() {
    return {
      tableKey: 1,
      disableInput: true,
      rows: [],
      attemptSubmit: false,
      groupUser: {
        _id: null,
        firstName: '',
        lastName: '',
        email: '',
        password: null
      },
      columns: [
        {
          label: 'First Name',
          field: 'firstName',
          width: '30%'
        },
        {
          label: 'Last Name',
          field: 'lastName',
          width: '30%'
        },
        {
          label: 'Email',
          field: 'email',
          width: '30%'
        }
      ]
    }
  },
  mounted() {
    console.log(`Groupusers created`)
    // Get group admin (purchaser info)
    this.$store.dispatch('subscriptions/getPurchaser', this.loggedinUser._id).then(result => {
      console.log('Got purchaser', result)
    })
    // Get all users connected with this group admin
    this.getGroupUsers()
    // this.$store.dispatch('subscriptions/getGroupUsers', this.loggedinUser._id).then(result => {
    //   this.rows = this.groupUsers
    //   console.log('Got groupUsers', result)
    // })
    // // Get subscription
    // this.$store.dispatch('subscriptions/subscriptionByPurchaser', this.loggedinUser._id).then(
    //   result => {
    //     console.log(`Get result from subscriptionByPurchaser`)
    //   },
    //   error => {
    //     console.error('Something wrong returning from subscriptionByPurchaser action', error)
    //   }
    // )
  },
  computed: {
    ...mapState({
      data: state => state.general.data,
      error: state => state.general.error,
      loadingMsg: state => state.general.loadingMsg,
      loading: state => state.general.loading,
      groupUsers: state => state.subscriptions.groupUsers,
      loggedinUser: state => state.general.loggedinUser,
      serverError: state => state.general.serverError,
      requestedLink: state => state.general.requestedLink
    }),
    canDelete() {
      return this.groupUser._id === null
    },
    formErrorText() {
      return this.formsHasErrors ? 'Please correct one or more errors' : ''
    },
    formsHasErrors() {
      if (this.attemptSubmit) {
        return this.missingFirstName || this.missingLastName || this.missingEmail || this.missingPassword || this.emailExists
      } else {
        return false
      }
    },
    missingFirstName() {
      return this.groupUser.firstName === ''
    },
    missingLastName() {
      return this.groupUser.lastName === ''
    },
    missingEmail() {
      return this.groupUser.email === ''
    },
    existingEmail() {
      return this.emailExists
    },
    missingPassword() {
      return (this.groupUser.password === null || this.groupUser.password === '') && !this.groupUser._id
    }
  },
  methods: {
    getGroupUsers() {
      this.$store.commit('general/setLoading', true)
      this.$store.dispatch('subscriptions/getGroupUsers', this.loggedinUser._id).then(result => {
        this.rows = this.groupUsers
        console.log('Got groupUsers', result)
        this.tableKey++
      })
    },
    deleteUser() {
      this.$store.dispatch('subscriptions/deleteGroupUser', this.groupUser._id).then(result => {
        this.rows = this.groupUsers
        console.log('Deleted groupUser', result)
        this.tableKey++
        this.disableInput = true
        this.groupUser = {
          _id: null,
          firstName: '',
          lastName: '',
          email: '',
          password: null
        }
      })
    },
    save() {
      // need to add id to subscription userIds
      this.attemptSubmit = true
      this.groupUser.groupAdminId = this.loggedinUser._id
      if (!this.formsHasErrors) {
        this.$store.dispatch('subscriptions/saveGroupUser', { user: this.groupUser })
        this.groupUser = {
          _id: null,
          firstName: '',
          lastName: '',
          email: '',
          password: null
        }
        this.disableInput = true
        this.attemptSubmit = false
      }
    },
    cancel() {
      this.attemptSubmit = false
      this.disableInput = true
      this.groupUser = {
        _id: null,
        firstName: '',
        lastName: '',
        email: '',
        password: null
      }
    },
    createPassword() {
      const pwOptions = {
        length: 8,
        excludeSimilarCharacters: true,
        numbers: true,
        symbols: false,
        uppercase: true,
        lowercase: true
      }
      return generator.generate(pwOptions)
    },
    rowClick(params) {
      this.disableInput = false
      this.groupUser.firstName = params.row.firstName
      this.groupUser.lastName = params.row.lastName
      this.groupUser.email = params.row.email
      this.groupUser.password = null
      this.groupUser._id = params.row._id
      this.$nextTick(function () {
        this.$refs['firstName'].focus()
      })
    },
    newGroupUser() {
      if (this.groupUsers.length >= this.loggedinUser.maxGroupMembers) {
        alert('You have reached your maximum group members')
      } else {
        this.disableInput = false
        this.groupUser = {
          _id: null,
          firstName: '',
          lastName: '',
          email: '',
          password: this.createPassword()
        }
        this.$nextTick(function () {
          this.$refs['firstName'].focus()
        })
      }
    }
  }
}
</script>

<style scoped>
.group-members {
  font-size: 14px;
  float: right;
}
</style>
