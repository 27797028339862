var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"below-menu"},[_c('div',{staticClass:"row fixed-top page-header"},[_vm._m(0),_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"btn btn-outline-primary btn-xs float-right header-btn",staticStyle:{"margin-right":"10px"},on:{"click":_vm.copyContent}},[_vm._v(" Copy ")])])]),_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
      enabled: true,
      perPage: _vm.serverParams.perPage,
      perPageDropdown: [5, 10, 15, 20, 25, 30, 35, 40, 50],
      position: 'top',
      mode: 'records'
    },"sort-options":{
      enabled: true,
      initialSortBy: [{ field: 'time', type: 'desc' }]
    },"rows":_vm.rows,"columns":_vm.columns,"style-class":"vgt-table musical bordered condensed"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.rowClick,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}}},[_c('div',{staticClass:"loading",attrs:{"slot":"loadingContent"},slot:"loadingContent"},[(_vm.loadingErrorMsg)?_c('div',{staticClass:"loading server-error"},[_vm._v(" "+_vm._s(_vm.loadingErrorMsg)+" ")]):_vm._e(),(_vm.isLoading)?_c('div',[_c('img',{attrs:{"src":"/loading.gif"}}),_vm._v(" "+_vm._s(_vm.isLoadingMsg)+" ")]):_vm._e()])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-10"},[_c('h5',[_vm._v("Stripe Messages")])])}]

export { render, staticRenderFns }