import Vue from 'vue'
import merge from 'lodash.merge'
import axios from 'axios'

/*
For hi-res timing use as below
import hirestime from 'hirestime'
const getElapsed = hirestime()
Vue.$log.debug(`Cleanup Song properties took ${getElapsed.ms()}ms`)
*/

export default {
  namespaced: true,
  state: {
    tableKey: 0,
    songsList: [],
    externalLinks: {
      scores: {
        musicNotes: { id: 'musicNotes', siteName: 'MusicNotes.com', enabled: true, automatic: true, includeSongTitle: true },
        sheetMusicPlus: {
          id: 'sheetMusicPlus',
          siteName: 'SheetMusicPlus.com',
          enabled: true,
          automatic: true,
          includeSongTitle: true
        },
        newMusicalTheatre: {
          id: 'newMusicalTheatre',
          siteName: 'NewMusicalTheatre.com',
          enabled: false,
          automatic: true,
          includeSongTitle: false
        },
        amazon: { id: 'amazon', siteName: 'Amazon.com', enabled: true, automatic: true, includeSongTitle: false },
        worldcat: {
          id: 'worldcat',
          siteName: 'WorldCat (library holdings)',
          enabled: true,
          automatic: true,
          includeSongTitle: false
        },
        contemporaryMusicalTheatre: {
          id: 'contemporaryMusicalTheatre',
          siteName: 'ContemporaryMusicalTheatre.com',
          enabled: false,
          automatic: false,
          includeSongTitle: false
        },
        custom: { enabled: false, siteName: '', url: '' }
      },
      recordings: {
        newMusicalTheatre: {
          id: 'newMusicalTheatre',
          siteName: 'NewMusicalTheatre.com',
          enabled: false,
          automatic: false,
          includeSongTitle: false
        },
        amazon: { id: 'amazon', siteName: 'Amazon.com', enabled: true, automatic: true, includeSongTitle: false },
        worldcat: {
          id: 'worldcat',
          siteName: 'WorldCat (library holdings)',
          enabled: true,
          automatic: true,
          includeSongTitle: false
        },
        contemporaryMusicalTheatre: {
          id: 'contemporaryMusicalTheatre',
          siteName: 'ContemporaryMusicalTheatre.com',
          enabled: false,
          automatic: false,
          includeSongTitle: false
        },
        iTunes: { id: 'iTunes', siteName: 'iTunes.com', enabled: true, automatic: true, includeSongTitle: false },
        spotify: { id: 'spotify', siteName: 'Spotify.com', enabled: true, automatic: true, includeSongTitle: true },
        soundCloud: { id: 'soundCloud', siteName: 'SoundCloud.com', enabled: false, automatic: true, includeSongTitle: false },
        gsarchive: {
          id: 'gsarchive',
          siteName: 'The Gilbert & Sullivan Archive',
          enabled: false,
          automatic: false,
          includeSongTitle: false
        },
        custom: { enabled: false, siteName: '', url: '' }
      }
    },
    newSong: {
      _id: '',
      title: '',
      showId: '',
      showTitle: '',
      showSortTitle: '',
      composers: [],
      lyricists: [],
      tags: [],
      enteredById: '',
      lastUpdatedById: '',
      dateShowCreated: 0,
      yearWritten: '',
      ageRanges: [],
      timeSignature: '',
      timeSignature1: '',
      timeSignature2: '',
      isComic: null,
      voiceType: '',
      tempo: '',
      originalKey: { majorMinor: '', pitch: '' },
      accompanistDifficulty: 0,
      dateCreated: 0,
      sortComposer: '',
      sortLyricist: '',
      lastUpdated: 0,
      sortTitle: '',
      wikipediaUrl: '',
      externalLinks: {
        scores: {
          musicNotes: { id: 'musicNotes', siteName: 'MusicNotes.com', enabled: true, automatic: true, includeSongTitle: true },
          sheetMusicPlus: {
            id: 'sheetMusicPlus',
            siteName: 'SheetMusicPlus.com',
            enabled: true,
            automatic: true,
            includeSongTitle: true
          },
          newMusicalTheatre: {
            id: 'newMusicalTheatre',
            siteName: 'NewMusicalTheatre.com',
            enabled: false,
            automatic: true,
            includeSongTitle: false
          },
          amazon: { id: 'amazon', siteName: 'Amazon.com', enabled: true, automatic: true, includeSongTitle: false },
          worldcat: {
            id: 'worldcat',
            siteName: 'WorldCat (library holdings)',
            enabled: true,
            automatic: true,
            includeSongTitle: false
          },
          contemporaryMusicalTheatre: {
            id: 'contemporaryMusicalTheatre',
            siteName: 'ContemporaryMusicalTheatre.com',
            enabled: false,
            automatic: false,
            includeSongTitle: false
          },
          custom: { enabled: false, siteName: '', url: '' }
        },
        recordings: {
          newMusicalTheatre: {
            id: 'newMusicalTheatre',
            siteName: 'NewMusicalTheatre.com',
            enabled: false,
            automatic: false,
            includeSongTitle: false
          },
          amazon: { id: 'amazon', siteName: 'Amazon.com', enabled: true, automatic: true, includeSongTitle: false },
          worldcat: {
            id: 'worldcat',
            siteName: 'WorldCat (library holdings)',
            enabled: true,
            automatic: true,
            includeSongTitle: false
          },
          contemporaryMusicalTheatre: {
            id: 'contemporaryMusicalTheatre',
            siteName: 'ContemporaryMusicalTheatre.com',
            enabled: false,
            automatic: false,
            includeSongTitle: false
          },
          iTunes: { id: 'iTunes', siteName: 'iTunes.com', enabled: true, automatic: true, includeSongTitle: false },
          spotify: { id: 'spotify', siteName: 'Spotify.com', enabled: true, automatic: true, includeSongTitle: true },
          soundCloud: { id: 'soundCloud', siteName: 'SoundCloud.com', enabled: false, automatic: true, includeSongTitle: false },
          gsarchive: {
            id: 'gsarchive',
            siteName: 'The Gilbert & Sullivan Archive',
            enabled: false,
            automatic: false,
            includeSongTitle: false
          },
          custom: { enabled: false, siteName: '', url: '' }
        }
      },
      pageNumbers: {
        start: '',
        end: ''
      },
      voiceRange: {
        highestNote: {
          midiNum: 0,
          note: '',
          octave: ''
        },
        lowestNote: {
          midiNum: 0,
          note: '',
          octave: ''
        }
      }
    },
    currentSong: {
      _id: '',
      title: '',
      showId: '',
      showTitle: '',
      showSortTitle: '',
      composers: [],
      lyricists: [],
      tags: [],
      enteredById: '',
      lastUpdatedById: '',
      dateShowCreated: 0,
      yearWritten: '',
      ageRanges: [],
      timeSignature: '',
      isComic: false,
      voiceType: '',
      tempo: '',
      originalKey: {},
      accompanistDifficulty: 0,
      dateCreated: 0,
      sortComposer: '',
      sortLyricist: '',
      lastUpdated: 0,
      sortTitle: '',
      externalLinks: {
        scores: {
          musicNotes: { id: 'musicNotes', siteName: 'MusicNotes.com', enabled: false, automatic: true, includeSongTitle: false },
          sheetMusicPlus: {
            id: 'sheetMusicPlus',
            siteName: 'SheetMusicPlus.com',
            enabled: false,
            automatic: true,
            includeSongTitle: false
          },
          newMusicalTheatre: {
            id: 'newMusicalTheatre',
            siteName: 'NewMusicalTheatre.com',
            enabled: false,
            automatic: false,
            includeSongTitle: false
          },
          amazon: { id: 'amazon', siteName: 'Amazon.com', enabled: false, automatic: true, includeSongTitle: false },
          worldcat: {
            id: 'worldcat',
            siteName: 'WorldCat (library holdings)',
            enabled: false,
            automatic: true,
            includeSongTitle: false
          },
          contemporaryMusicalTheatre: {
            id: 'contemporaryMusicalTheatre',
            siteName: 'ContemporaryMusicalTheatre.com',
            enabled: false,
            automatic: false,
            includeSongTitle: false
          },
          custom: { enabled: false, siteName: '', url: '' }
        },
        recordings: {
          newMusicalTheatre: {
            id: 'newMusicalTheatre',
            siteName: 'NewMusicalTheatre.com',
            enabled: false,
            automatic: false,
            includeSongTitle: false
          },
          amazon: { id: 'amazon', siteName: 'Amazon.com', enabled: false, automatic: true, includeSongTitle: false },
          worldcat: {
            id: 'worldcat',
            siteName: 'WorldCat (library holdings)',
            enabled: false,
            automatic: true,
            includeSongTitle: false
          },
          contemporaryMusicalTheatre: {
            id: 'contemporaryMusicalTheatre',
            siteName: 'ContemporaryMusicalTheatre.com',
            enabled: false,
            automatic: false,
            includeSongTitle: false
          },
          iTunes: { id: 'iTunes', siteName: 'iTunes.com', enabled: false, automatic: true, includeSongTitle: true },
          spotify: { id: 'spotify', siteName: 'Spotify.com', enabled: false, automatic: true, includeSongTitle: false },
          soundCloud: { id: 'soundCloud', siteName: 'SoundCloud.com', enabled: false, automatic: true, includeSongTitle: false },
          gsarchive: {
            id: 'gsarchive',
            siteName: 'The Gilbert & Sullivan Archive',
            enabled: false,
            automatic: false,
            includeSongTitle: false
          },
          custom: { enabled: false, siteName: '', url: '' }
        }
      },
      pageNumbers: {
        start: '',
        end: ''
      },
      voiceRange: {
        highestNote: {
          midiNum: 0,
          note: '',
          octave: ''
        },
        lowestNote: {
          midiNum: 0,
          note: '',
          octave: ''
        }
      }
    }
  },
  mutations: {
    setSongsList: (state, payload) => {
      state.songsList = payload
    },
    setTableKey: (state) => {
      state.tableKey++
    }
  },
  actions: {
    getFromServer: async ({ state, commit, rootState }, serverParams) => {
      return new Promise(async (resolve, reject) => {
        const result = await axios
          .post(rootState.general.apiRoot + '/api/songs', serverParams, {
            headers: { Authorization: rootState.general.loggedinUser.token }
          })
          .catch(err => {
            if (err.response) {
              Vue.$log.error(`Error getting songs from server: ${err.response.data}`)
              return reject(err.response.data)
            } else {
              Vue.$log.error(`Error getting songs from server: ${err}`)
              return reject(err)
            }
          })
        if (result) {
          Vue.$log.info(`Total received: ${result.data.count}`)
          commit('setSongsList', result.data.songs)
          resolve({ totalRecords: result.data.count })
        } else {
          resolve({ totalRecords: 0, rows: [] })
        }
      })
    },
    getSongsForShow: ({ commit, rootState }, showId) => {
      return new Promise(async (resolve, reject) => {
        const result = await axios
          .get(rootState.general.apiRoot + '/api/songsforshow/' + showId, {
            headers: { Authorization: rootState.general.loggedinUser.token }
          })
          .catch(err => {
            if (err.response) {
              Vue.$log.error(`Error getting songs from server: ${err.response.data}`)
              return reject(err.response.data)
            } else {
              Vue.$log.error(`Error getting songs from server: ${err}`)
              return reject(err)
            }
          })
        if (result) {
          // throw new Error(`Holy shit, something wlong`)
          commit('setSongsList', result.songs)
          resolve('songs')
        }
      })
      // if (state.songsList.length === 0) {
      //   rootState.general.error = ''
      //   rootState.general.loading = true
      //   rootState.general.loadingMsg = 'Loading Songs'
      //   Vue.$log.info('Loading Songs')
      //   Vue.http.get('api/songs').then(
      //     response => {
      //       commit('setSongsList', response.body)
      //       rootState.general.loading = false
      //       rootState.general.loadingMsg = ''
      //       resolve('songs')
      //     },
      //     error => {
      //       rootState.general.error = error.bodyText
      //       reject(error.bodyText)
      //     }
      //   )
      // } else {
      //   resolve('songs')
      // }
    },
    setNewSong: ({ state, dispatch, rootState }, payload) => {
      // if Tags/Glossary not yet loaded, load them
      if (rootState.glossary.tagsList.length === 0) {
        Vue.$log.info('Getting tags/glossary')
        dispatch('glossary/getTags', null, { root: true })
          .then(result => {
            Vue.$log.info(`Got ${result}`)
            dispatch('setNewSong', payload)
          })
          .catch(ex => {
            console.error('Exception in setNewSong - getTags', ex.message)
          })
      } else if (rootState.general.commentsList.length === 0) {
        // if Comments not yet loaded, load them
        Vue.$log.info('Getting comments')
        dispatch('general/getComments', null, { root: true })
          .then(result => {
            Vue.$log.info(`Got ${result}`)
            dispatch('setNewSong', payload)
          })
          .catch(ex => {
            console.error('Exception in setNewSong - getComments', ex.message)
          })
      } else {
        state.currentSong = {
          _id: '',
          title: '',
          showId: '',
          showTitle: '',
          showSortTitle: '',
          comments: '',
          composers: payload.composers,
          lyricists: payload.lyricists,
          tags: [],
          enteredById: '',
          comment: '',
          lastUpdatedById: '',
          dateShowCreated: 0,
          yearWritten: payload.yearWritten,
          ageRanges: [],
          timeSignature: '',
          timeSignature1: '',
          timeSignature2: '',
          isComic: null,
          voiceType: '',
          tempo: '',
          originalKey: { majorMinor: '', pitch: '' },
          accompanistDifficulty: 0,
          dateCreated: 0,
          sortComposer: '',
          sortLyricist: '',
          lastUpdated: 0,
          sortTitle: '',
          wikipediaUrl: '',
          externalLinks: {
            scores: {
              musicNotes: {
                id: 'musicNotes',
                siteName: 'MusicNotes.com',
                enabled: true,
                automatic: true,
                includeSongTitle: true
              },
              sheetMusicPlus: {
                id: 'sheetMusicPlus',
                siteName: 'SheetMusicPlus.com',
                enabled: true,
                automatic: true,
                includeSongTitle: true
              },
              newMusicalTheatre: {
                id: 'newMusicalTheatre',
                siteName: 'NewMusicalTheatre.com',
                enabled: false,
                automatic: true,
                includeSongTitle: false
              },
              amazon: { id: 'amazon', siteName: 'Amazon.com', enabled: true, automatic: true, includeSongTitle: false },
              worldcat: {
                id: 'worldcat',
                siteName: 'WorldCat (library holdings)',
                enabled: true,
                automatic: true,
                includeSongTitle: false
              },
              contemporaryMusicalTheatre: {
                id: 'contemporaryMusicalTheatre',
                siteName: 'ContemporaryMusicalTheatre.com',
                enabled: false,
                automatic: false,
                includeSongTitle: false
              },
              custom: { enabled: false, siteName: '', url: '' }
            },
            recordings: {
              newMusicalTheatre: {
                id: 'newMusicalTheatre',
                siteName: 'NewMusicalTheatre.com',
                enabled: false,
                automatic: false,
                includeSongTitle: false
              },
              amazon: { id: 'amazon', siteName: 'Amazon.com', enabled: true, automatic: true, includeSongTitle: false },
              worldcat: {
                id: 'worldcat',
                siteName: 'WorldCat (library holdings)',
                enabled: true,
                automatic: true,
                includeSongTitle: false
              },
              contemporaryMusicalTheatre: {
                id: 'contemporaryMusicalTheatre',
                siteName: 'ContemporaryMusicalTheatre.com',
                enabled: false,
                automatic: false,
                includeSongTitle: false
              },
              iTunes: { id: 'iTunes', siteName: 'iTunes.com', enabled: true, automatic: true, includeSongTitle: false },
              spotify: { id: 'spotify', siteName: 'Spotify.com', enabled: true, automatic: true, includeSongTitle: true },
              soundCloud: {
                id: 'soundCloud',
                siteName: 'SoundCloud.com',
                enabled: false,
                automatic: true,
                includeSongTitle: false
              },
              gsarchive: {
                id: 'gsarchive',
                siteName: 'The Gilbert & Sullivan Archive',
                enabled: false,
                automatic: false,
                includeSongTitle: false
              },
              custom: { enabled: false, siteName: '', url: '' }
            }
          },
          pageNumbers: {
            start: '',
            end: ''
          },
          voiceRange: {
            highestNote: {
              midiNum: 0,
              note: '',
              octave: ''
            },
            lowestNote: {
              midiNum: 0,
              note: '',
              octave: ''
            }
          }
        }
        // Adjust some song properties so the component understands
        setSongProperties(state.currentSong, state.externalLinks)
        state.currentSong.timeSignature1 = state.currentSong.timeSignature.substr(0, state.currentSong.timeSignature.indexOf('/'))
        state.currentSong.timeSignature2 = state.currentSong.timeSignature.substr(
          state.currentSong.timeSignature.indexOf('/') + 1
        )
        if (payload) {
          state.currentSong.showId = payload.showId
          state.currentSong.showTitle = payload.showTitle
        }
        state.currentSong.dateCreated = Date.now()
        state.currentSong.dateShowCreated = rootState.shows.currentShow.dateCreated
        state.currentSong.enteredById = rootState.general.loggedinUser._id
      }
    },
    getSong: ({ state, commit, dispatch, rootState }, rowId) => {
      if (rootState.glossary.tagsList.length === 0) {
        Vue.$log.info('Getting tags/glossary')
        dispatch('glossary/getTags', null, { root: true })
          .then(result => {
            Vue.$log.info(`Got ${result}`)
            dispatch('getSong', rowId)
          })
          .catch(ex => {
            console.error('Exception in getSong - getTags', ex.message)
          })
      } else if (rootState.general.commentsList.length === 0) {
        // if Comments not yet loaded, load them
        Vue.$log.info('Getting comments')
        dispatch('general/getComments', null, { root: true })
          .then(result => {
            Vue.$log.info(`Got ${result}`)
            dispatch('getSong', rowId)
          })
          .catch(ex => {
            console.error('Exception in getSong - getComments', ex.message)
          })
      } else if (rootState.creators.composersList.length === 0) {
        Vue.$log.info('Getting composers')
        dispatch('creators/getComposers', null, { root: true })
          .then(result => {
            Vue.$log.info(`Got ${result}`)
            dispatch('getSong', rowId)
          })
          .catch(ex => {
            console.error('Exception in getSong - getComposers', ex.message)
          })
        // If no lyricists, get them
      } else if (rootState.creators.lyricistsList.length === 0) {
        Vue.$log.info('Getting lyricists')
        dispatch('creators/getLyricists', null, { root: true })
          .then(result => {
            Vue.$log.info(`Got ${result}`)
            dispatch('getSong', rowId)
          })
          .catch(ex => {
            console.error('Exception in getSong - getLyricists', ex.message)
          })
      } else {
        rootState.general.error = ''
        rootState.general.loading = true
        rootState.general.loadingMsg = 'Loading Song'
        Vue.http.get('api/songs/' + rowId).then(
          response => {
            commit('general/dataLoaded', null, { root: true })
            rootState.general.loading = false
            const newSong = {
              _id: '',
              title: '',
              showId: '',
              showTitle: '',
              showSortTitle: '',
              comments: '',
              composers: [],
              lyricists: [],
              tags: [],
              enteredById: '',
              comment: '',
              lastUpdatedById: '',
              dateShowCreated: 0,
              yearWritten: '',
              ageRanges: [],
              timeSignature: '',
              timeSignature1: '',
              timeSignature2: '',
              isComic: null,
              voiceType: '',
              tempo: '',
              originalKey: { majorMinor: '', pitch: '' },
              accompanistDifficulty: 0,
              dateCreated: 0,
              sortComposer: '',
              sortLyricist: '',
              lastUpdated: 0,
              sortTitle: '',
              wikipediaUrl: '',
              pageNumbers: { start: 1, end: 1 },
              voiceRange: {
                highestNote: {
                  midiNum: 0,
                  note: '',
                  octave: ''
                },
                lowestNote: {
                  midiNum: 0,
                  note: '',
                  octave: ''
                }
              },
              externalLinks: {
                scores: {
                  musicNotes: {
                    id: 'musicNotes',
                    siteName: 'MusicNotes.com',
                    enabled: true,
                    automatic: true,
                    includeSongTitle: true
                  },
                  sheetMusicPlus: {
                    id: 'sheetMusicPlus',
                    siteName: 'SheetMusicPlus.com',
                    enabled: true,
                    automatic: true,
                    includeSongTitle: true
                  },
                  newMusicalTheatre: {
                    id: 'newMusicalTheatre',
                    siteName: 'NewMusicalTheatre.com',
                    enabled: false,
                    automatic: true,
                    includeSongTitle: false
                  },
                  amazon: {
                    id: 'amazon',
                    siteName: 'Amazon.com',
                    enabled: true,
                    automatic: true,
                    includeSongTitle: false
                  },
                  worldcat: {
                    id: 'worldcat',
                    siteName: 'WorldCat (library holdings)',
                    enabled: true,
                    automatic: true,
                    includeSongTitle: false
                  },
                  contemporaryMusicalTheatre: {
                    id: 'contemporaryMusicalTheatre',
                    siteName: 'ContemporaryMusicalTheatre.com',
                    enabled: false,
                    automatic: false,
                    includeSongTitle: false
                  },
                  custom: { enabled: false, siteName: '', url: '' }
                },
                recordings: {
                  newMusicalTheatre: {
                    id: 'newMusicalTheatre',
                    siteName: 'NewMusicalTheatre.com',
                    enabled: false,
                    automatic: false,
                    includeSongTitle: false
                  },
                  amazon: {
                    id: 'amazon',
                    siteName: 'Amazon.com',
                    enabled: true,
                    automatic: true,
                    includeSongTitle: false
                  },
                  worldcat: {
                    id: 'worldcat',
                    siteName: 'WorldCat (library holdings)',
                    enabled: true,
                    automatic: true,
                    includeSongTitle: false
                  },
                  contemporaryMusicalTheatre: {
                    id: 'contemporaryMusicalTheatre',
                    siteName: 'ContemporaryMusicalTheatre.com',
                    enabled: false,
                    automatic: false,
                    includeSongTitle: false
                  },
                  iTunes: {
                    id: 'iTunes',
                    siteName: 'iTunes.com',
                    enabled: true,
                    automatic: true,
                    includeSongTitle: false
                  },
                  spotify: {
                    id: 'spotify',
                    siteName: 'Spotify.com',
                    enabled: true,
                    automatic: true,
                    includeSongTitle: true
                  },
                  soundCloud: {
                    id: 'soundCloud',
                    siteName: 'SoundCloud.com',
                    enabled: false,
                    automatic: true,
                    includeSongTitle: false
                  },
                  gsarchive: {
                    id: 'gsarchive',
                    siteName: 'The Gilbert & Sullivan Archive',
                    enabled: false,
                    automatic: false,
                    includeSongTitle: false
                  },
                  custom: { enabled: false, siteName: '', url: '' }
                }
              }
            }
            state.currentSong = merge(newSong, response.body)
            for (var composer of state.currentSong.composers) {
              composer.label = composer.lastName + ' ' + composer.firstName
            }
            for (var lyricist of state.currentSong.lyricists) {
              lyricist.label = lyricist.lastName + ' ' + lyricist.firstName
            }
            // Adjust some song properties so the component understands
            setSongProperties(state.currentSong, state.externalLinks)
            state.currentSong.timeSignature1 = state.currentSong.timeSignature.substr(
              0,
              state.currentSong.timeSignature.indexOf('/')
            )
            state.currentSong.timeSignature2 = state.currentSong.timeSignature.substr(
              state.currentSong.timeSignature.indexOf('/') + 1
            )
          },
          error => {
            rootState.general.loading = false
            commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          }
        )
      }
    },
    saveSong: async ({ state, rootState, dispatch }) => {
      rootState.general.loadingMsg = 'Saving Song'
      rootState.general.loading = true
      cleanUpSongProperties(state.currentSong, rootState.general.loggedinUser._id)
      const result = await axios
        .post(
          rootState.general.apiRoot + '/api/songs/saveorupdate',
          { song: state.currentSong },
          { headers: { Authorization: rootState.general.loggedinUser.token } }
        )
        .catch(error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        })
      rootState.general.loading = false
      rootState.general.loadingMsg = ''
    },
    saveCommon: ({ commit, dispatch, rootState }, editedProperties) => {
      rootState.general.loading = true
      rootState.general.loadingMsg = 'Saving Common Song Properties'
      Vue.http.post('api/songs/updateCommon', { editedProperties }).then(
        () => {
          Vue.$log.info('Songs common properties updated')
          // Reload show, some changed songinfo may have effects on show
          dispatch('shows/getShow', rootState.shows.currentShow._id, { root: true })
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
      rootState.general.loadingMsg = ''
      rootState.general.loading = false
    },
    deleteSong: ({ state, commit, rootState }, songId) => {
      Vue.http.delete('api/songs/' + songId).then(
        () => {
          // Set new song to make sure the deleted song cannot be seen
          state.currentSong = JSON.parse(JSON.stringify(state.newSong))
          Vue.$log.info('[store.songs] deleted song ', songId)
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    }
  }
}

const getMidiNoteNum = (noteName, octaveNum) => {
  // Middle C = C4 in this system
  // Middle C = MIDI note 60
  const midiNoteMap = {
    C: 0,
    'C♯': 1,
    'D♭': 1,
    D: 2,
    'D♯': 3,
    'E♭': 3,
    E: 4,
    F: 5,
    'F♯': 6,
    'G♭': 6,
    G: 7,
    'G♯': 8,
    'A♭': 8,
    A: 9,
    'B♭': 10,
    'A♯': 10,
    B: 11
  }
  var relativeNoteVal = midiNoteMap[noteName]
  if (relativeNoteVal === undefined) {
    throw new Error("Unrecognized note: '" + noteName + "'")
  }
  return 12 * (octaveNum + 1) + relativeNoteVal
}

const cleanUpSongProperties = (currentSong, userId) => {
  delete currentSong.u13
  delete currentSong.a1417
  delete currentSong.a1824
  delete currentSong.a2532
  delete currentSong.a3339
  delete currentSong.a4059
  delete currentSong.a60
  // delete currentSong.songs => should not be here I guess. Remove if confirmed
  currentSong.isComic = currentSong.isComic === 'true' || typeof(currentSong.isComic) === 'boolean' ? currentSong.isComic : false
  currentSong.timeSignature = currentSong.timeSignature1 + '/' + currentSong.timeSignature2
  currentSong.voiceRange.lowestNote.midiNum = getMidiNoteNum(
    currentSong.voiceRange.lowestNote.note,
    currentSong.voiceRange.lowestNote.octave
  )
  currentSong.voiceRange.highestNote.midiNum = getMidiNoteNum(
    currentSong.voiceRange.highestNote.note,
    currentSong.voiceRange.highestNote.octave
  )
  currentSong.lastUpdated = new Date()
  currentSong.lastUpdatedById = userId
  delete currentSong.lastUpdatedByName
  delete currentSong.timeSignature1
  delete currentSong.timeSignature2
}

const setSongProperties = (currentSong, externalLinks) => {
  // Set age ranges to a format the component understands
  currentSong.u13 = currentSong.ageRanges.find(a => a === 'Under 13') !== undefined
  currentSong.a1417 = currentSong.ageRanges.find(a => a === '14-17') !== undefined
  currentSong.a1824 = currentSong.ageRanges.find(a => a === '18-24') !== undefined
  currentSong.a2532 = currentSong.ageRanges.find(a => a === '25-32') !== undefined
  currentSong.a3339 = currentSong.ageRanges.find(a => a === '33-39') !== undefined
  currentSong.a4059 = currentSong.ageRanges.find(a => a === '40-59') !== undefined
  currentSong.a60 = currentSong.ageRanges.find(a => a === '60+') !== undefined
  // Check for missing properties in External Links and set defaults so missing external links will not give errors
  if (!currentSong.externalLinks.recordings.amazon) {
    currentSong.externalLinks.recordings.amazon = externalLinks.recordings.amazon
  }
  if (!currentSong.externalLinks.recordings.iTunes) {
    currentSong.externalLinks.recordings.iTunes = externalLinks.recordings.iTunes
  }
  if (!currentSong.externalLinks.recordings.spotify) {
    currentSong.externalLinks.recordings.spotify = externalLinks.recordings.spotify
  }
  if (!currentSong.externalLinks.recordings.soundCloud) {
    currentSong.externalLinks.recordings.soundCloud = externalLinks.recordings.soundCloud
  }
  if (!currentSong.externalLinks.recordings.worldcat) {
    currentSong.externalLinks.recordings.worldcat = externalLinks.recordings.worldcat
  }
  if (!currentSong.externalLinks.recordings.contemporaryMusicalTheatre) {
    currentSong.externalLinks.recordings.contemporaryMusicalTheatre = externalLinks.recordings.contemporaryMusicalTheatre
  }
  if (!currentSong.externalLinks.recordings.newMusicalTheatre) {
    currentSong.externalLinks.recordings.newMusicalTheatre = externalLinks.recordings.newMusicalTheatre
  }
  if (!currentSong.externalLinks.recordings.gsarchive) {
    currentSong.externalLinks.recordings.gsarchive = externalLinks.recordings.gsarchive
  }
  if (!currentSong.externalLinks.recordings.custom) {
    currentSong.externalLinks.recordings.custom = externalLinks.recordings.custom
  }
  if (!currentSong.externalLinks.scores.musicNotes) {
    currentSong.externalLinks.scores.musicNotes = externalLinks.scores.musicNotes
  }
  if (!currentSong.externalLinks.scores.sheetMusicPlus) {
    currentSong.externalLinks.scores.sheetMusicPlus = externalLinks.scores.sheetMusicPlus
  }
  if (!currentSong.externalLinks.scores.newMusicalTheatre) {
    currentSong.externalLinks.scores.newMusicalTheatre = externalLinks.scores.newMusicalTheatre
  }
  if (!currentSong.externalLinks.scores.amazon) {
    currentSong.externalLinks.scores.amazon = externalLinks.scores.amazon
  }
  if (!currentSong.externalLinks.scores.worldcat) {
    currentSong.externalLinks.scores.worldcat = externalLinks.scores.worldcat
  }
  if (!currentSong.externalLinks.scores.contemporaryMusicalTheatre) {
    currentSong.externalLinks.scores.contemporaryMusicalTheatre = externalLinks.scores.contemporaryMusicalTheatre
  }
  if (!currentSong.externalLinks.scores.custom) {
    currentSong.externalLinks.scores.custom = externalLinks.scores.custom
  }
}
