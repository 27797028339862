import Vue from 'vue'
import App from './App'
import VueRouter from 'vue-router'
import store from './store'
import VueResource from 'vue-resource'
import Toasted from 'vue-toasted'
import VueGoodTable from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import VModal from 'vue-js-modal'
import { abilitiesPlugin } from '@casl/vue'
import getRule from './acl/abilities'
import VTooltip from 'v-tooltip'
import moment from 'moment'
// see https://github.com/sagalbot/vue-select/pull/394
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { routes } from "./routes/index";
import VueNativeSock from "vue-native-websocket";
import VueQuillEditor from "vue-quill-editor";
// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// import merge from './plugins/deepMerge'
import VueLogger from 'vuejs-logger'

let role = null
const isProduction = process.env.NODE_ENV === 'production'
const logOptions = {
  isEnabled: true,
  logLevel: isProduction ? 'warn' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
}

Vue.use(VueRouter)
Vue.use(VueResource)
Vue.use(VueGoodTable)
Vue.use(VTooltip)
Vue.use(Toasted)
Vue.use(VModal, { dialog: true })
Vue.use(VueQuillEditor)
Vue.use(VueLogger, logOptions)

Vue.config.productionTip = false;

Vue.component("v-select", vSelect);

let wsUrl = "";
Vue.http.options.root = process.env.VUE_APP_LINK
store.commit('general/setApiRoot', process.env.VUE_APP_LINK)
if (process.env.VUE_APP_LINK.includes('https')) {
  // Set web sockets
  wsUrl = Vue.http.options.root.replace('https', 'wss')
} else {
  wsUrl = Vue.http.options.root.replace('http', 'ws')
}
// }
console.log(`App is connected to ${Vue.http.options.root} - ${process.env.VUE_APP_LINK} - ${store.state.general.apiRoot}`)
console.log(`version from VUE_APP_VERSION: ${process.env.VUE_APP_VERSION}, version from state: ${store.state.general.version}`)

Vue.use(VueNativeSock, wsUrl, {
  store: store,
  format: "json",
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5000, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000 // (Number) how long to initially wait before attempting a new (1000)
});

// Setup routes
const router = new VueRouter({
  mode: "hash",
  routes
});

// Set version
store.commit('general/setVersion')

// Set loggedinUser and token expiration from local storage
store.dispatch('general/getUser')

// Check every client route if allowed
router.beforeEach((to, from, next) => {
  console.log(`Main-Global BeforeEach. To: ${to.path}, From: ${from.path}`)
  // Remember the path if it's not '/'
  if (to.path !== '/' && to.path !== '/login') {
    console.log(`Main - Setting requested link to ${to.path}`)
    store.commit('general/setRequestedLink', to.path)
  }

  if (to.name === 'Login') {
    console.log(`Main - Login path requested`)
    next()
  }

  if (store.state.general.loggedinUser && store.state.general.loggedinUser.role === 'groupadmin') {
    next()
  }
  // Redirect if there is no loggedinUser and not already requesting /login
  if (to.name !== 'Login' && !store.state.general.loggedinUser) {
    console.log(`Main - Path requested ${to.name} but not logged in. Redirecting to /login`)
    next({ name: 'Login' })
    // Check token expiry
  } else if (store.state.general.loggedinUser && store.state.general.loggedinUser.expiry < Date.now()) {
    console.log(`Main - Path requested ${to.name} is logged in but token expired. Redirecting to /login`)
    next({ name: 'Login' })
    // Check if user allowed to the route
  } else if (store.state.general.resources.indexOf(to.name.toLowerCase()) > -1 || store.state.general.resources[0] === 'all') {
    console.log(`Main - Path requested ${to.name} and allowed. Resources: ${store.state.general.resources[0]}`)
    next()
  } else {
    if (store.state.general.loggedinUser && store.state.general.loggedinUser.role === 'groupadmin') {
      next({ name: 'groupusers' })
    } else {
      console.log(`Main - Not allowed to route to ${to.name}. Resources: ${store.state.general.resources[0]}. Redirecting /login`)
      next({ name: 'Login' })
    }
  }
  // if (to.name) {
  //   console.log(`Main: Trying to route to ${to.name.toLowerCase()} with resources of ${store.state.general.resources.toString()}`)
  //   if (store.state.general.resources.indexOf(to.name.toLowerCase()) > -1 || store.state.general.resources[0] === 'all') {
  //     next()
  //   } else {
  //     console.log('Main: Not allowed to route to', to.name)
  //     next({ name: 'Login', redirect: to.path })
  //   }
  // } else {
  //   next(false)
  // }
})

// Set the auth header for every request
Vue.http.interceptors.push(request => {
  if (store.state.general.loggedinUser) {
    request.headers.set('Authorization', store.state.general.loggedinUser.token)
  }
  return response => {
    if (response.status === 401) {
      console.log('Got unauthorized response', response)
      store.commit('general/setUser', null)
      store.commit('general/setServerError', 'Unauthorized')
      router.push('/login')
    }
  };
});

// Set currentMenuItem to path of location, this is to set active class on initial page load
// store.state.general.activeMenuItem = location.hash.substr(2).substr(0, 1).toUpperCase() + location.hash.substr(3)
// if (location.hash === '#/') {
//   store.state.general.activeMenuItem = 'Shows'
// }

Vue.filter('formatDate', value => {
  if (value) {
    return moment(value).format("MMM DD YYYY");
  } else {
    return "Unknown";
  }
});

Vue.filter('formatEpoch', value => {
  if (value) {
    // Check if date is in seconds
    const tmpDate = value.toString()
    if (tmpDate.length === 10) {
      value = value * 1000
    }
    return moment(value).format('MMM DD YYYY - HH:mm')
  } else {
    return 'Unknown'
  }
});

Vue.filter('emptyVal', value => {
  if (!value) {
    return "Unknown";
  }
  if (value.indexOf("undefined") > -1) {
    return "Unknown";
  }
  return value;
});

Vue.filter('roundAmount', value => {
  return Number.parseFloat(value).toFixed(2)
})

Vue.filter('formatSize', function(size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + " MB";
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + " KB";
  }
  return size.toString() + " B";
});

// Create an eventbus
export const eventbus = new Vue();

// Check token validity if user in local storage
// store.state.general.loggedinUser = JSON.parse(localStorage.getItem('user'))
// const loggedinUser = store.dispatch('general/getUser')
// if (loggedinUser && Object.prototype.hasOwnProperty.call(loggedinUser, 'expiry')) {
//   console.log(`LoggedinUser ${loggedinUser.email} found, checking expiry`)
//   const now = moment()
//   const expDate = moment(loggedinUser.expiry)
//   const duration = moment.duration(expDate.diff(now, 'milliseconds')).humanize()
//   console.log(`Token is valid for ${duration}`)
//   console.log('Token:', loggedinUser.token)
//   if (expDate.isSameOrBefore(now)) {
//     console.log('Token expired')
//     store.dispatch('general/setUser', null)
//     router.push({ name: 'Login', query: { redirect: router.path } })
//   } else {
//     // Emit the loggedIn event to trigger route protection
//     // Below in this file. We wait a bit so the event handler will be created
//     setTimeout(() => {
//       eventbus.$emit('loggedIn', loggedinUser)
//     }, 1000)
//     role = loggedinUser.role
//   }
// } else {
//   // debugger
//   // console.log(`No stored user found. Request was ${router.currentRoute.path}`)
//   // // router.push('/login')
//   // router.push({ name: 'Login', params: { redirect: router.currentRoute.path } })
// }

Vue.use(abilitiesPlugin, getRule(role));

eventbus.$on('loggedIn', user => {
  console.log('Main: Received login with role', user.role)
  store.commit('general/setResources', user.role)
  console.log('Main: Route resources for user', store.state.general.resources.toString())
})

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App)
});
