var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"below-menu"},[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('img',{attrs:{"src":"/loading.gif"}}),_c('div',{staticClass:"loading"},[_vm._v(" "+_vm._s(_vm.loadingMsg)+" ")])]):_vm._e(),_c('div',{staticClass:"row fixed-top page-header"},[_vm._m(0),_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"btn btn-outline-primary btn-xs float-right header-btn",on:{"click":_vm.copyContent}},[_vm._v(" Copy ")])])]),_c('vue-good-table',{key:_vm.tableKey,attrs:{"mode":"remote","totalRows":_vm.totalRecords,"pagination-options":{
      enabled: true,
      perPage: _vm.serverParams.perPage,
      perPageDropdown: [5, 10, 15, 20, 25, 30, 35, 40, 50],
      position: 'top',
      mode: 'records'
    },"sort-options":{
      enabled: true,
      initialSortBy: [{ field: 'showTitle', type: 'asc' }]
    },"rows":_vm.rows,"columns":_vm.columns,"style-class":"vgt-table musical bordered condensed"},on:{"on-row-click":_vm.rowClick,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-10"},[_c('h5',[_vm._v("Songs")])])}]

export { render, staticRenderFns }