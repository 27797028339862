<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
      <div class="title-block">
        <a class="navbar-text navbar-right version title-row nav-title">Administration</a>
        <a class="navbar-text navbar-right version title-row">Version: {{ version }}{{ appType }}</a>
      </div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon" />
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav" v-if="loggedinUser">
          <li class="nav-item">
            <a class="nav-link" v-if="$can('view', 'shows')"
              :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Shows' }" @click="menuClicked"
              href="#/shows">Shows</a>
          </li>
          <li class="nav-item">
            <a class="nav-item nav-link" v-if="$can('view', 'songs')"
              :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Songs' }" @click="menuClicked"
              href="#/songs">Songs</a>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" ref="help" @click="dropdownOpenedHelp = !dropdownOpenedHelp"
              v-if="$can('view', 'help')" :class="{ disabled: !loggedinUser }" id="navbarDropdownMenuLinkHelp"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Help</a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLinkHelp"
              :class="{ dropdownOpened: dropdownOpenedHelp, dropdownClosed: !dropdownOpenedHelp }">
              <a class="dropdown-item nav-link" v-if="$can('view', 'help')"
                :class="{ disabled: !loggedinUser, active: activeMenuItem === 'FAQ' }" @click="menuClicked"
                href="#/faq">FAQ</a>
              <a class="dropdown-item nav-link" v-if="$can('view', 'help')"
                :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Glossary' }" @click="menuClicked"
                href="#/glossary">Glossary</a>
              <a class="dropdown-item nav-link" v-if="$can('view', 'help')"
                :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Resources' }" @click="menuClicked"
                href="#/resources">Resources</a>
            </div>
          </li>

          <!-- <li class="nav-item">
            <a
              class="nav-item nav-link"
              v-if="$can('view', 'faq')"
              :class="{ disabled: !loggedinUser, active: activeMenuItem === 'FAQ' }"
              @click="menuClicked"
              href="#/faq"
              >FAQ</a
            >
          </li> -->
          <!-- <li class="nav-item">
            <a
              class="nav-item nav-link"
              v-if="$can('view', 'glossary')"
              :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Glossary' }"
              @click="menuClicked"
              href="#/glossary"email-block
              >Glossary</a
            >
          </li> -->
          <li class="nav-item">
            <a class="nav-item nav-link" v-if="$can('view', 'users')"
              :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Users' }" @click="menuClicked"
              href="#/users">Users</a>
          </li>
          <li class="nav-item">
            <a class="nav-item nav-link" v-if="$can('view', 'subscriptions')"
              :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Subscriptions' }" @click="menuClicked"
              href="#/subscriptions">Subscriptions</a>
          </li>
          <li class="nav-item">
            <a class="nav-item nav-link" v-if="$can('view', 'composers')"
              :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Composers' }" @click="menuClicked"
              href="#/composers">Composers</a>
          </li>
          <li class="nav-item">
            <a class="nav-item nav-link" v-if="$can('view', 'lyricists')"
              :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Lyricists' }" @click="menuClicked"
              href="#/lyricists">Lyricists</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" ref="misc" @click="dropdownOpened1 = !dropdownOpened1"
              v-if="$can('view', 'miscellaneous')" :class="{ disabled: !loggedinUser }" id="navbarDropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Miscellaneous</a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink"
              :class="{ dropdownOpened: dropdownOpened1, dropdownClosed: !dropdownOpened1 }">
              <a class="dropdown-item nav-link" v-if="$can('view', 'miscellaneous')"
                :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Comment Templates' }"
                @click="menuClicked" href="#/commentTemplates">Comment Templates</a>
              <a class="dropdown-item nav-link" v-if="$can('view', 'miscellaneous')"
                :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Tag Management' }" @click="menuClicked"
                href="#/tags">Tag Management</a>
              <a class="dropdown-item nav-link" v-if="$can('view', 'miscellaneous')"
                :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Institutional Coupon Codes' }"
                @click="menuClicked" href="#/coupons">Institutional Coupon Codes</a>
            </div>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" ref="admin" @click="dropdownOpened2 = !dropdownOpened2"
              v-if="$can('view', 'admin')" :class="{ disabled: !loggedinUser }" id="navbarDropdownMenuLinkAdmin"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Admin</a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLinkAdmin"
              :class="{ dropdownOpened: dropdownOpened2, dropdownClosed: !dropdownOpened2 }">
              <a class="dropdown-item nav-link" v-if="$can('view', 'admin')"
                :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Logs' }" @click="menuClicked"
                href="#/logs">Logs
              </a>
              <a class="dropdown-item nav-link" v-if="$can('view', 'miscellaneous')"
                :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Email Log' }" @click="menuClicked"
                href="#/emails">Email Log</a>
              <a class="dropdown-item nav-link" v-if="$can('view', 'admin')"
                :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Stripe Messages' }" @click="menuClicked"
                href="#/stripe-msgs">Stripe Messages
              </a>
              <a class="dropdown-item nav-link" v-if="$can('view', 'admin')"
                :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Statistcs' }" @click="menuClicked"
                href="#/statistics">Statistcs
              </a>
              <a class="dropdown-item nav-link" v-if="$can('view', 'admin')"
                :class="{ disabled: !loggedinUser, active: activeMenuItem === 'Status' }" @click="menuClicked"
                href="https://stats.uptimerobot.com/x19JNuJwZW" target="_blank">Status
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div class="navbar-nav" v-if="loggedinUser">
        <a id="email" class="nav-item nav-link active email-block" href="#">{{ loggedinUser.email }}</a>
        <a class="nav-item nav-link active" href="#" @click="logout">Logout</a>
        <span :class="[socket.isConnected ? 'green-dot' : 'red-dot']" />
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import getRule from '../acl/abilities'
// const ABILITIES = require('../acl/abilities').ABILITIES
import { eventbus } from '../main'
export default {
  data() {
    return {
      dropdownOpenedHelp: false,
      dropdownOpened1: false,
      dropdownOpened2: false,
      rules: null,
      hover: false
    }
  },
  computed: {
    ...mapGetters('general', ['loggedinUser', 'activeMenuItem', 'serverVersion', 'version']),
    ...mapState({
      // version: state => state.general.version,
      socket: state => state.socket,
      // loggedinUser: state => state.general.loggedinUser,
      // activeMenuItem: state => state.general.activeMenuItem,
      // serverVersion: state => state.general.serverVersion,
      appType: function () {
        if (this.$http.options.root.includes('dev')) {
          return '-T' + this.serverVersion
        } else if (this.$http.options.root.includes('localhost')) {
          return '-L' + this.serverVersion
        }
        return '-P' + this.serverVersion
      }
    })
  },
  created() {
    console.log('MainMenu component created')
    eventbus.$on('loggedIn', user => {
      console.log(`MainMenu created, loggedIn event received: ${user}`)
      console.log(`MainMenu: Role for loggedin user: ${this.loggedinUser.role}`)
      this.rules = getRule(this.loggedinUser.role).rules
      console.log('MainMenu: Rules:', this.rules)
      this.$ability.update(this.rules)
      console.log(`MainMenu: Role for loggedin user: ${this.loggedinUser.role} with rule ${this.rules[0].subject}`)
      console.log('MainMenu: Rules from Ability', this.$ability.rules[0].subject)
    })
    if (this.loggedinUser) {
      console.log(`MainMenu: Role for loggedin user: ${this.loggedinUser.role}`)
      this.rules = getRule(this.loggedinUser.role).rules
      console.log('MainMenu: Rules:', this.rules)
      this.$ability.update(this.rules)
      console.log(`MainMenu: Role for loggedin user: ${this.loggedinUser.role} with rule ${this.rules[0].subject}`)
      console.log('MainMenu: Rules from Ability', this.$ability.rules[0].subject)
    }

    // Set default pages for different roles
    // if (this.$can('view', 'shows')) {
    //   this.$router.push('shows')
    // } else if (this.$can('view', 'subscriptions')) {
    //   this.$router.push('subscriptions')
    // } else {
    //   this.$router.push('/')
    // }
  },
  methods: {
    logout() {
      this.$store.dispatch('general/setUser', null)
      this.$router.push('/login')
    },
    menuClicked(event) {
      console.log('[MainMenu] menuClicked')
      // navbarDropdownMenuLink
      // Set the active class on the Miscellaneous menu item
      if (event.currentTarget.parentNode.parentNode.firstChild.innerText === 'Miscellaneous') {
        event.currentTarget.parentNode.parentNode.firstChild.classList.add('active')
        this.$refs['admin'].classList.remove('active')
      } else if (event.currentTarget.parentNode.parentNode.firstChild.innerText === 'Admin') {
        event.currentTarget.parentNode.parentNode.firstChild.classList.add('active')
        this.$refs['misc'].classList.remove('active')
      } else if (event.currentTarget.parentNode.parentNode.firstChild.innerText === 'Help') {
        event.currentTarget.parentNode.parentNode.firstChild.classList.add('active')
        this.$refs['misc'].classList.remove('active')
      } else {
        if (this.loggedinUser.role === 'admin') {
          this.$refs['admin'].classList.remove('active')
          this.$refs['misc'].classList.remove('active')
          this.$refs['help'].classList.remove('active')
        }
        // if (this.$refs['misc']) {
        //   this.$refs['misc'].classList.remove('active')
        // }
        // if (this.$refs['admin']) {
        //   this.$refs['admin'].classList.remove('active')
        // }
      }
      this.dropdownOpened1 = false
      this.dropdownOpened2 = false
      this.dropdownOpenedHelp = false
      this.$store.commit('general/setMenuHighlight', event.currentTarget.innerText)
      console.log('[MainMenu] sending close-all to modals')
      eventbus.$emit('modal', { command: 'close-all' })
    }
  }
}
</script>

<style>
.title-block {
  margin-left: -10px;
  margin-right: 16px;
  width: 144px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.nav-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  padding: 0;
}
.title-row {
  padding: 0;
}
@media screen and (max-width: 999px) {
  .email-block {
    display: none;
  }
}
</style>