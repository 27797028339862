<template>
  <div>
    <div class="row">
      <div class="col-12">
        <input type="checkbox" :name="linkName" :id="linkName" class="css-checkbox" v-model="model.active" />
        <label :for="linkName" class="css-label lite-red-check">{{ label }}</label>
      </div>
      <!-- <input type="checkbox" :name="linkName" :id="linkName" class="css-checkbox" v-model="model.active" /> -->
    </div>
    <!-- model active: {{model.active}} -->
    <div v-if="model.active" class="external-link">
      <div class="row">
        <div class="col-12">
          <input type="radio" :name="linkName + 'radio'" v-model="model.type" value="automatic" />
          <label :for="linkName + 'radio'"
            >Automatic Link
            <span @click="testAutoScore" v-if="model.type === 'automatic'" class="link">Test Link</span>
          </label>
        </div>
      </div>
      <div class="row" v-if="model.type === 'automatic'">
        <div class="col-6">
          <input
            type="checkbox"
            :name="linkName + 'check'"
            :id="type + linkName + 'check'"
            class="css-checkbox"
            v-model="model.includeTitle"
          />
          <label :for="linkName + 'check'" class="css-label lite-red-check external-link">
            Include song title in addition to show title in automatic link
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <input type="radio" :name="linkName + 'radio'" v-model="model.type" value="manual" />
          <label :for="linkName + 'radio'"
            >Manual Link
            <span @click="testManualScore" v-if="model.type === 'manual'" class="link">Test Link</span>
          </label>
        </div>
      </div>
      <div class="row" v-if="model.type === 'manual'">
        <div class="col-12">
          <input type="text" class="external-link" v-model="model.manualLink" placeholder="http://" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: ['songs', 'showTitle', 'linkName', 'label', 'baseUrl', 'model', 'type'],
    methods: {
      testAutoScore() {
        let url
        if (this.model.includeTitle) {
          url = encodeURI(`${this.showTitle}" "${this.songs[0].title}`)
        } else {
          url = encodeURI(`${this.showTitle}`)
        }
        window.open(this.baseUrl + url, '_blank')
      },
      testManualScore() {
        window.open(this.model.manualLink, '_blank')
      }
    }
  }
</script>

<!-- 
  scores custom:  https://newmusicshelf.com/product/trans-nonbinary-v1/
  recordings custom: https://mickiewadsworth.weebly.com/


-->